import axios from 'axios'
import { readonly } from 'vue'
import devalue from '@nuxt/devalue'
import viteSSR, { ClientOnly } from '@monofront/vite-ssr/vue'
import { getGPUTier } from 'detect-gpu'

// Vue plugins
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import { svgSpritePlugin } from 'vue-svg-sprite'
import i18n from '@/i18n'

// Highlight.js
import 'highlight.js/styles/stackoverflow-dark.css'
import hljs from 'highlight.js/lib/core'
import javascript from 'highlight.js/lib/languages/javascript'
import typescript from 'highlight.js/lib/languages/typescript'
import xml from 'highlight.js/lib/languages/xml'
import scss from 'highlight.js/lib/languages/scss'
import glsl from 'highlight.js/lib/languages/glsl'
import csharp from 'highlight.js/lib/languages/csharp'

// Vue components
import App from '@/core/App.vue'
import Action from '@/components/global/Action.vue'
import Btn from '@/components/global/Btn.vue'
import Cookies from '@/components/global/Cookies.vue'
import CtaRounded from '@/components/global/CtaRounded.vue'
// import HtmlText from '@/components/global/HtmlText.vue'
// import Lazy from '@/components/global/Lazy.vue'
import Picture from '@/components/global/Picture.vue'

// Vue directives
import outside from '@/directives/outside'
import src from '@/directives/src'

import { langDefault } from '@/config/languages'
import { ContentKey, contentRef } from '@/core/resource'
import { createGuards } from '@/router/guards'
import routes from '@/router/routes'
import { useChromeStore } from '@/stores/chrome'
import { useNavigationStore } from '@/stores/navigation'

import { loadFonts } from '@/config/fonts'
import { logger } from '@/utils/logger'
import { push } from '@/utils/tracking'

import '@/styles/main.scss'
import 'virtual:svg-icons-register'
import { useUiStore } from '@/stores/ui'

export default viteSSR(
  App,
  {
    routes,
    routerOptions: {
      scrollBehavior() {
        return false
      },
    },
    // https://pinia.esm.dev/ssr/#state-hydration
    transformState(state) {
      return import.meta.env.SSR ? devalue(state) : state
    },
    pageProps: {
      passToPage: false, // ALL props will be passed to the page component.
      // [Vue warn]: Extraneous non-props attributes (head) were passed to component
      // but could not be automatically inherited because component renders fragment or text root nodes.
    },
  },
  {
    main: async context => {
      const { app, initialState, initialRoute } = context

      import.meta.env.VITE_RELEASE !== 'production' && console.time('font')
      if (!import.meta.env.SSR) {
        await loadFonts()
        logger.log(document.fonts.size, 'FontFaces loaded.')
      }
      import.meta.env.VITE_RELEASE !== 'production' && console.timeEnd('font')

      // GTM: EPIC env
      push({ epicEnvironment: import.meta.env.VITE_RELEASE as string })

      // Plugins
      const head = createHead()
      const pinia = createPinia()

      app.use(head)
      app.use(i18n)
      app.use(pinia)
      app.use(svgSpritePlugin, {
        url: '',
      })

      if (!import.meta.env.SSR) {
        hljs.registerLanguage('javascript', javascript)
        hljs.registerLanguage('typescript', typescript)
        hljs.registerLanguage('html', xml)
        hljs.registerLanguage('scss', scss)
        hljs.registerLanguage('glsl', glsl)
        hljs.registerLanguage('csharp', csharp)
      }
      // eslint-disable-next-line vue/component-definition-name-casing
      // app.component('highlightjs', hljsVuePlugin.component)

      // Components
      app.component(ClientOnly.name || 'ClientOnly', ClientOnly)
      app.component('GAction', Action)
      app.component('GCtaRounded', CtaRounded)
      // app.component('GHtmlText', HtmlText)
      // app.component('GLazy', Lazy)
      app.component('GBtn', Btn)
      app.component('GCookies', Cookies)
      app.component('GPicture', Picture)

      // Directives
      app.directive('outside', outside)
      app.directive('src', src)

      // Other…
      app.provide(ContentKey, readonly(contentRef.value))

      // Set default language
      const lang = (initialRoute.params.lang as string) || langDefault
      axios.defaults.headers.common['Accept-Language'] = lang as string

      createGuards(context, pinia)

      if (import.meta.env.SSR) {
        const chrome = useChromeStore(pinia)

        await chrome.fetchChrome()
        // This will be stringified and set to window.__INITIAL_STATE__
        initialState.pinia = pinia.state.value
      } else {
        // On the client side, we restore the state.
        // This should be done before calling any useStore() function on client side !!!
        pinia.state.value = initialState.pinia

        const navigation = useNavigationStore()
        navigation.referrer = document.referrer

        const ui = useUiStore()
        ui.gpuStats = await getGPUTier()
      }

      return { head }
    },
  }
)
