<template>
  <div
    ref="rootRef"
    :class="{
      'custom-foreground': foreground,
      'custom-background': background,
    }"
    class="socials"
  >
    <a
      v-for="link in links"
      :key="link.type"
      :href="link.url"
      :aria-label="capitalize(link.type)"
      class="socials__link"
      @click.prevent="onSocialClick(link.url)"
    >
      <SvgSprite
        :symbol="`social-${link.type}`"
        size="16"
        class="socials__link__icon"
      />
    </a>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType } from 'vue'
import { SocialLink } from '@/stores/chrome'
import { capitalize } from '@/utils/case'
import { push } from '@/utils/tracking'

defineProps({
  links: {
    type: Array as PropType<SocialLink[]>,
    default: () => [],
  },
  background: {
    type: String,
    default: null,
  },
  foreground: {
    type: String,
    default: null,
  },
})

const rootRef = ref<HTMLElement>()

const onSocialClick = (href: string) => {
  const ctx = rootRef.value?.closest('.mega-menu')
  const ctaLocation = ctx ? 'menu' : 'footer'

  push({
    event: 'social_links',
    ctaLocation,
    linkUrl: href,
    eventCallback() {
      window.open(href, '_blank')?.focus()
    },
  })
}
</script>

<style lang="scss" scoped>
.socials {
  display: flex;
  align-items: center;

  @include mq($until: xxl) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.socials__label {
  margin-right: 1.6rem;
  color: var(--c-foreground);

  .custom-foreground & {
    color: v-bind('foreground');
  }

  @include mq($until: xxl) {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 2rem;
    text-align: center;
  }
}

.socials__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.6rem;
  height: 3.6rem;
  line-height: 1;
  border: 1px solid var(--c-foreground);
  border-radius: 50%;
  fill: var(--c-foreground);
  transition: background-color 0.15s, fill 0.15s;

  .custom-foreground & {
    border-color: v-bind('foreground');
    fill: v-bind('foreground');
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &:hover {
    background-color: var(--c-foreground);
    fill: var(--c-background);

    .custom-foreground & {
      background-color: v-bind('foreground');
    }

    .custom-background & {
      fill: v-bind('background');
    }
  }
}

.socials__link__icon {
  fill: inherit;
}
</style>
