<template>
  <canvas
    v-show="isVisible"
    ref="canvasRef"
    class="portfolio-poster is-full-viewport"
  ></canvas>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  reactive,
  onMounted,
  ref,
  onBeforeMount,
  Ref,
  onBeforeUnmount,
} from 'vue'
import { useMouse } from '@vueuse/core'
import gsap from 'gsap'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import ScrollTrigger from 'gsap/ScrollTrigger'
import {
  DoubleSide,
  LinearFilter,
  Mesh,
  PerspectiveCamera,
  PlaneGeometry,
  Raycaster,
  RepeatWrapping,
  Scene,
  ShaderMaterial,
  Texture,
  TextureLoader,
  Vector2,
  WebGLRenderer,
} from 'three'

import { Sim } from '@/components/portfolio/fbo/Sim'
import { SimMaterial } from '@/components/portfolio/fbo/SimMaterial'
import posterVert from '@/components/portfolio/shaders/poster.vert?raw'
import posterFrag from '@/components/portfolio/shaders/poster.frag?raw'
// import { getRgbaString, toRgb } from '@/modules/colors'
// import { toRgb } from '@/modules/colors'
import { useChromeStore } from '@/stores/chrome'
import { useUiStore } from '@/stores/ui'
import { CaseHighlight } from '@/types/views/cases'

import { useResize, useViewport, RafCallback } from '@/utils/aware'
import { logger } from '@/utils/logger'
import { Picture } from '@/types'
import { setBackground, setForeground } from '@/modules/colors'
import Ticker from '@/modules/Ticker'
import { useResourceStore } from '@/stores/resource'

gsap.registerPlugin(MotionPathPlugin)
gsap.registerPlugin(ScrollTrigger)

// type MovePosition = number | 'center'
type MoveTemplate = 'home' | 'case' | 'portfolio'

interface PosterApi {
  isFreeScroll: boolean
  isAnimating: boolean
  canvasRef: Ref<HTMLCanvasElement | null>
  current?: CaseHighlight
  show(): Promise<void>
  hide(animate: boolean): Promise<void>
  initOrChange(item: CaseHighlight, kind: 'init' | 'change'): Promise<void>
  init?(item: CaseHighlight, isFreeScroll?: boolean): Promise<void>
  change?(item: CaseHighlight, isFreeScroll?: boolean): Promise<void>
  position?(y: number): void
  move?(
    data: { template: MoveTemplate; offset?: Ref<number> | null },
    animate?: boolean
  ): Promise<void>
}

interface PosterTextures {
  base: Record<string, Texture>
  effects: Record<string, Texture>
  normals: Record<string, Texture>
  noise?: Texture
  paper?: Texture
}

export const poster = {
  isFreeScroll: false,
  isAnimating: false,
  canvasRef: ref(null),
} as PosterApi

export default defineComponent({
  setup() {
    const resourceStore = useResourceStore()
    const chrome = useChromeStore()
    const ui = useUiStore()

    /**
     * Canvas and animation
     */
    // TODO: refacto
    // if (!uiStore.cases.isFullViewport && uiStore.cases.items.length > 0) {
    //   params.color = getRgbaString(
    //     uiStore.cases.items[uiStore.cases.current].colors.background
    //   )
    // }

    let ticker: Ticker
    const canvasRef = ref<HTMLCanvasElement | null>(null)
    const isVisible = ref(false)
    let isRunning = false
    let isFront = false
    const params = {
      color: 'rgba(0,0,0,0)',
    }

    /**
     * Viewport, mouse, positions, …
     */
    const { width, height, ratio: r } = useViewport()
    // TODO : minimum ratio === 1 !!!
    const customHeight = ref(height.value)
    const minRatio = 1

    let savedTemplate: MoveTemplate | null = null
    let savedOffset: Ref<number> | null = null

    if (r.value <= minRatio) {
      customHeight.value = width.value * minRatio
    }

    const ratio = ref(width.value / customHeight.value)
    const { x: mouseX, y: mouseY } = useMouse({ touch: false })
    const mouseOffset = reactive({ x: 0, y: 0 })
    const mousePos = computed(() => ({
      /* eslint-disable no-mixed-operators */
      x: (mouseX.value / width.value) * 2 - 1,
      y: -((mouseY.value - mouseOffset.y) / height.value) * 2 + 1,
      /* eslint-enable no-mixed-operators */
    }))
    let scrollWidth = 0

    /**
     * Three.js
     */
    // Global
    const camera = new PerspectiveCamera(50, ratio.value, 0.1, 100)
    const raycaster = new Raycaster()
    const scene = new Scene()
    let renderer: WebGLRenderer
    // Textures
    const textureLoader = new TextureLoader()
    const textures: PosterTextures = {
      base: {},
      effects: {},
      normals: {},
    }
    // TODO: assets, async loader, kindof cache
    // Poster
    const posterGeometry = new PlaneGeometry(1, 1, 64, 64)
    const posterMaterial = new ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        ratioFlying: { value: 1 },
        map: { value: null },
        mapNoise: { value: null },
        mapPaper: { value: null },
        mapEffects: { value: null },
        mapNormals: { value: null },
        mapPush: { value: null },
        useNormals: { value: 0 },
        mousePos: { value: new Vector2(0, 0) },
      },
      vertexShader: posterVert,
      fragmentShader: posterFrag,
      transparent: true,
      side: DoubleSide,
    })
    const posterMesh = new Mesh(posterGeometry, posterMaterial)

    // FBO
    const simMat = new SimMaterial()
    let sim: Sim

    const setSize = (w: number, h: number) => {
      width.value = w

      const r = w / h

      if (r <= minRatio) {
        customHeight.value = w * minRatio
      } else {
        customHeight.value = h
      }

      ratio.value = w / customHeight.value

      onResize()
    }

    const flyIn = async () => {
      if (!poster.current) {
        return
      }

      try {
        const color = poster.current.colors.background
        const tl = gsap.timeline()

        isFront = true

        await tl
          .add('start')
          .fromTo(
            posterMesh.position,
            {
              x: posterMesh.position.x,
              y: posterMesh.position.y,
              z: posterMesh.position.z,
            },
            {
              x: 0,
              y: 0,
              z: 3,
              duration: 1,
              ease: 'power4.in',
            },
            'start'
          )
          .to(
            posterMesh.rotation,
            {
              x: 0,
              y: 0,
              z: 0,
              duration: 1,
              ease: 'power4.in',
            },
            'start'
          )
          .to(
            posterMesh.scale,
            {
              x: 1,
              y: 1,
              z: 1,
              duration: 1,
              ease: 'power4.out',
            },
            'start'
          )
          .to(
            posterMesh.material.uniforms.ratioFlying,
            {
              value: 0,
              duration: 1.5,
              ease: 'power4.inOut',
            },
            'start'
          )
          .to(
            params,
            {
              color,
              duration: 1,
              ease: 'power4.in',
              onUpdate: () => {
                // const rgb = toRgb(params.color)
                // renderer.setClearColor(new Color(rgb))
              },
            },
            'start'
          )
          .then()
      } catch (error) {
        logger.error(error)
      }
    }

    const flyOut = async (between = true, animate = true) => {
      if (!poster.current) {
        return
      }

      try {
        const from = between
          ? { x: 0, y: 0, z: -1 } // case BETWEEN case transtion
          : { x: -1, y: -1, z: -5 } // initial transition
        const to = { x: 3, y: 2, z: 3 }

        const positionPath = {
          path: [from, to],
        }

        const rotationPath = {
          path: [
            {
              x: 0.2,
              y: 2.5,
              z: 1.5,
            },
            {
              x: (Math.random() * Math.PI) / 2,
              y: (Math.random() * Math.PI) / 2,
              z: (Math.random() * Math.PI) / 2,
            },
          ],
        }
        const tl = gsap.timeline()

        isFront = false

        await tl
          .add('start')
          .to(
            posterMesh.material.uniforms.ratioFlying,
            {
              value: 1,
              duration: animate ? 0.5 : 0,
              ease: 'power1.out',
            },
            'start'
          )
          .to(
            posterMesh.position,
            {
              motionPath: positionPath,
              duration: animate ? 1 : 0,
              ease: 'power1.in',
            },
            'start'
          )
          .to(
            posterMesh.rotation,
            {
              motionPath: rotationPath,
              duration: animate ? 1 : 0,
              ease: 'power1.in',
            },
            'start'
          )
          .then()
      } catch (error) {
        logger.error(error)
      }
    }

    const getPictureSource = (pic: Picture, isFull = false) => {
      const { sets } = pic
      let src = sets['960']

      if (isFull) {
        src = sets['2560']

        if (window.devicePixelRatio < 2 || !ui.isSmallLarger) {
          src = sets['1280']
        }
      }

      return src
    }

    const updateTextures = async () => {
      if (!poster.current) {
        return
      }

      const { visual, slug } = poster.current

      let basePromise = null

      if (visual.base) {
        basePromise = textures.base[slug]
          ? Promise.resolve(textures.base[slug])
          : textureLoader.loadAsync(getPictureSource(visual.base, true))
      }

      let effectsPromise = null

      if (visual.effects) {
        effectsPromise = textures.effects[slug]
          ? Promise.resolve(textures.effects[slug])
          : textureLoader.loadAsync(getPictureSource(visual.effects, true))
      }

      let normalsPromise = null

      if (visual.normals) {
        normalsPromise = textures.normals[slug]
          ? Promise.resolve(textures.normals[slug])
          : textureLoader.loadAsync(getPictureSource(visual.normals, true))
      }

      const loaded = await Promise.all([
        basePromise,
        effectsPromise,
        normalsPromise,
      ])

      const [baseTexture, effectsTexture, normalsTexture] = loaded

      if (baseTexture) {
        baseTexture.wrapS = RepeatWrapping
        baseTexture.wrapT = RepeatWrapping
        baseTexture.minFilter = LinearFilter
        textures.base[slug] = baseTexture
        posterMaterial.uniforms.map.value = baseTexture
      } else {
        posterMaterial.uniforms.map.value = null
      }

      if (effectsTexture) {
        effectsTexture.wrapS = RepeatWrapping
        effectsTexture.wrapT = RepeatWrapping
        textures.effects[slug] = effectsTexture
        posterMaterial.uniforms.mapEffects.value = effectsTexture
      } else {
        posterMaterial.uniforms.mapEffects.value = null
      }

      if (normalsTexture) {
        normalsTexture.wrapS = RepeatWrapping
        normalsTexture.wrapT = RepeatWrapping
        textures.normals[slug] = normalsTexture
        posterMaterial.uniforms.mapNormals.value = normalsTexture
        posterMaterial.uniforms.useNormals.value = 1
      } else {
        posterMaterial.uniforms.mapNormals.value = null
        posterMaterial.uniforms.useNormals.value = 0
      }
    }

    const resetPoster = () => {
      posterMesh.position.x = -1
      posterMesh.position.y = -1
      posterMesh.position.z = -5
      // posterMesh.position.x = 0
      // posterMesh.position.y = 0
      // posterMesh.position.z = -1
      posterMesh.scale.x = 0
      posterMesh.scale.y = 0
      posterMesh.scale.z = 0
      posterMesh.rotation.x = (Math.random() * Math.PI) / 4
      posterMesh.rotation.y = (Math.random() * Math.PI) / 4
      posterMesh.rotation.z = (Math.random() * Math.PI) / 8
      posterMesh.material.uniforms.ratioFlying.value = 1
    }

    const highQualityRender = ui.deviceTier >= 2
    const onRaf: RafCallback = time => {
      if (!renderer || !isRunning || !isVisible.value) {
        return
      }

      // const elapsedTime = clock.getElapsedTime()
      const elapsedTime = time

      posterMaterial.uniforms.time.value = elapsedTime
      simMat.time = elapsedTime

      if (isFront && highQualityRender && !ui.gpuStats?.isMobile) {
        // Follow mouse
        const mouse = new Vector2(mousePos.value.x, mousePos.value.y)

        raycaster.setFromCamera(mouse, camera)

        const intersects = raycaster.intersectObjects(scene.children)

        if (intersects.length) {
          const obj = intersects[0].object

          simMat.uniforms.mousePos.value = intersects[0].uv
          ;(
            obj as Mesh<PlaneGeometry, ShaderMaterial>
          ).material.uniforms.mousePos.value = intersects[0].uv
        }
      }

      if (highQualityRender && !ui.gpuStats?.isMobile) {
        sim.render()
        renderer.setRenderTarget(null)
        posterMesh.material.uniforms.mapPush.value = sim.output.texture
      }

      renderer.render(scene, camera)
    }

    const onResize = () => {
      if (!renderer) {
        return
      }

      if (width.value === 0 || height.value === 0) {
        return
      }

      camera.aspect = ratio.value
      camera.updateProjectionMatrix()
      renderer.setSize(width.value - scrollWidth, customHeight.value)

      if (savedTemplate !== null) {
        poster.move?.({ template: savedTemplate, offset: savedOffset }, false)
      }
    }

    const onHide = async (animate: boolean) => {
      if (!canvasRef.value) {
        return
      }

      try {
        await gsap
          .timeline()
          .to(canvasRef.value, {
            opacity: 0,
            duration: 0.35,
            ease: 'power2.out',
            clearProps: 'opacity',
          })
          .set(canvasRef.value, {
            y: 0,
            clearProps: 'y',
          })
          .then()
        isVisible.value = false
        await flyOut(false, animate)
      } catch (error) {
        logger.error(error)
      }
    }

    const onShow = async () => {
      if (!canvasRef.value) {
        return
      }

      try {
        await gsap
          .to(canvasRef.value, {
            opacity: 1,
            duration: 0.5,
            clearProps: 'opacity',
          })
          .then()
        isVisible.value = true
      } catch (error) {
        logger.error(error)
      }
    }

    const resetColors = () => {
      const isWorkPage = resourceStore.slug === 'work'

      if (isWorkPage || !isVisible.value) {
        return
      }

      setForeground(resourceStore.content.colors.foreground)
      setBackground(resourceStore.content.colors.background)
    }

    // Assign API
    poster.initOrChange = async function initOrChange(
      item: CaseHighlight,
      kind: 'init' | 'change'
    ) {
      if (!canvasRef.value) {
        return
      }

      this.current = item
      this.isFreeScroll = true

      isVisible.value = true
      this.isAnimating = true

      try {
        kind === 'change' && (await flyOut())
        await updateTextures()
        resetPoster()
        await flyIn()
      } catch (error) {
        logger.error(error)
      }

      this.isAnimating = false
    }

    poster.init = async function init(item: CaseHighlight) {
      await this.initOrChange(item, 'init')
    }

    poster.change = async function change(item: CaseHighlight) {
      await this.initOrChange(item, 'change')
    }

    poster.position = function position(y: number) {
      if (!canvasRef.value) {
        return
      }

      gsap.set(canvasRef.value, {
        y,
      })
    }

    poster.move = async function move(data, animate = true) {
      const { template, offset } = data
      // Default position. Apply to 'portfolio' template
      let y = 0

      savedTemplate = template
      savedOffset = offset || null

      // On homepage + small width
      if (template === 'home') {
        const offset = ui.isMediumLarger ? 0 : -50
        const top = height.value * 0.5
        const yPercent = customHeight.value * -0.5

        y = top + yPercent + offset
      } else if (template === 'case' && offset) {
        y = offset.value
      }

      if (animate) {
        await gsap.to(canvasRef.value, {
          y,
          duration: 0.5,
        })
      } else {
        await gsap.set(canvasRef.value, {
          y,
        })
      }

      mouseOffset.y = y
    }

    poster.show = () => onShow()

    poster.hide = (animate = true) => onHide(animate)

    onBeforeMount(() => {
      resetPoster()
    })

    onMounted(async () => {
      if (!canvasRef.value) {
        return
      }

      ticker = new Ticker(onRaf)
      poster.canvasRef = canvasRef

      scrollWidth = parseInt(
        window
          .getComputedStyle(document.documentElement)
          .getPropertyValue('--scrollbar-width')
          .replace('px', ''),
        10
      )
      useResize(({ width, height }) => {
        setSize(width, height)
      })

      const [noise, paper] = await Promise.all([
        textureLoader
          .loadAsync(chrome.noise ? chrome.noise.src : '/textures/noise.png')
          .then(texture => {
            texture.wrapS = RepeatWrapping
            texture.wrapT = RepeatWrapping

            return texture
          }),
        textureLoader
          .loadAsync(
            chrome.paperFold ? chrome.paperFold.src : '/textures/paper-fold.jpg'
          )
          .then(texture => {
            texture.wrapS = RepeatWrapping
            texture.wrapT = RepeatWrapping

            return texture
          }),
      ])

      textures.noise = noise
      textures.paper = paper

      posterMaterial.uniforms.mapNoise.value = textures.noise
      posterMaterial.uniforms.mapPaper.value = textures.paper

      renderer = new WebGLRenderer({
        canvas: canvasRef.value,
        antialias: true,
        alpha: true,
      })
      // renderer.setClearColor(toRgb(params.color))
      renderer.setSize(width.value - scrollWidth, customHeight.value)
      renderer.setPixelRatio(Math.min(1.5, window.devicePixelRatio))
      sim = new Sim(renderer, 128, 128, simMat)

      camera.position.set(0, 0, 4.08)
      scene.add(camera)
      scene.add(posterMesh)

      ScrollTrigger.create({
        start: 'top bottom',
        end: 'bottom top',
        onEnter: () => {
          // castleData.world.castle.randomConfiguration()
          isRunning = true
          if (ui.deviceTier < 2) {
            ticker.fps(30)
          }
        },
        onEnterBack: () => {
          isRunning = true
          if (ui.deviceTier < 2) {
            ticker.fps(30)
          }
          resetColors()
        },
        onLeave: () => {
          isRunning = false
          ticker.fps(60)
        },
        onLeaveBack: () => {
          isRunning = false
          ticker.fps(60)
        },
        trigger: canvasRef.value,
      })

      // useRaf(onRaf)
      if (ui.deviceTier < 2) {
        ticker.fps(30)
      }
    })

    onBeforeUnmount(() => {
      ticker.destroy()
    })

    return {
      isVisible,
      canvasRef,
    }
  },
})
</script>

<style lang="scss" scoped>
.portfolio-poster,
[class*='portfolio-poster--'] {
  width: 100%;
  outline: none;

  &.is-full-viewport {
    position: absolute;
    inset: 0;
  }
}
</style>
