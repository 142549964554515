import axios from 'axios'
import { createI18n } from 'vue-i18n'

import { Language } from '@/types'
import { langDefault, langAvailable } from '@/config/languages'
import en from '@/i18n/locales/en.json'

const messages = langAvailable.reduce((acc, lang) => {
  acc[lang] = {}

  return acc
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}, {} as Record<Language, any>)

messages[langDefault] = en

const i18n = createI18n({
  locale: langDefault,
  fallbackLocale: langDefault,
  globalInjection: true,
  messages,
})

export default i18n

const loadedLanguages = [langDefault] // Our default language that is preloaded

const loadLocaleMessages = (lang: Language) => import(`./locales/${lang}.json`)

export function setI18nLanguage(lang: Language, isClient: boolean) {
  i18n.global.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang

  if (isClient) {
    // Not really needed because of `vue-meta`…
    // however document is only available client side
    document.documentElement.lang = lang
  }

  return lang
}

export async function loadLanguageAsync(lang: Language): Promise<Language> {
  if (loadedLanguages.includes(lang)) {
    return lang
  }

  // If the language hasn't been loaded yet
  const messages = await loadLocaleMessages(lang)

  i18n.global.setLocaleMessage(lang, messages)
  loadedLanguages.push(lang)

  return lang
}
