export const loadFonts = () => {
  // const sangBleuKingdomLight = new FontFace(
  //   'sang-bleu',
  //   'url(/fonts/SangBleuKingdom-Light.otf)',
  //   {
  //     style: 'normal',
  //     weight: '100',
  //   }
  // )

  const sangBleuKingdomAir = new FontFace(
    'sang-bleu',
    'url(/fonts/SangBleuKingdom-Air-WebS.woff2)',
    {
      style: 'normal',
      weight: '200',
    }
  )

  const sangBleuEmpireRegular = new FontFace(
    'sang-bleu',
    'url(/fonts/SangBleuEmpire-Regular-WebS.woff2)',
    {
      style: 'normal',
      weight: '400',
    }
  )

  // const sangBleuEmpireMedium = new FontFace(
  //   'sang-bleu',
  //   'url(/fonts/SangBleuEmpire-Medium.otf)',
  //   {
  //     style: 'normal',
  //     weight: '500',
  //   }
  // )

  const sangBleuEmpireBlack = new FontFace(
    'sang-bleu',
    'url(/fonts/SangBleuEmpire-Black-WebS.woff2)',
    {
      style: 'normal',
      weight: '900',
    }
  )

  const interRegular = new FontFace(
    'inter',
    'url(/fonts/inter-v11-latin-regular.woff2)',
    {
      style: 'normal',
      weight: '400',
    }
  )

  const interSemiBold = new FontFace(
    'inter',
    'url(/fonts/inter-v11-latin-600.woff2)',
    {
      style: 'normal',
      weight: '600',
    }
  )

  const interBold = new FontFace(
    'inter',
    'url(/fonts/inter-v11-latin-700.woff2)',
    {
      style: 'normal',
      weight: '700',
    }
  )

  // Fonts 15Y
  const ppHattonUltralight = new FontFace(
    'PPHatton',
    'url(/fonts/PPHatton-Ultralight.woff2)',
    {
      style: 'normal',
      weight: '200',
    }
  )

  const ppHattonLight = new FontFace(
    'PPHatton',
    'url(/fonts/PPHatton-Light.woff2)',
    {
      style: 'normal',
      weight: '300',
    }
  )

  const ppHattonMedium = new FontFace(
    'PPHatton',
    'url(/fonts/PPHatton-Medium.woff2)',
    {
      style: 'normal',
      weight: '500',
    }
  )

  // This only generates a small glitch (portfolio, set item width)
  // solved by adding <link rel="preload"…
  document.fonts.add(sangBleuKingdomAir)
  document.fonts.add(sangBleuEmpireRegular)
  document.fonts.add(sangBleuEmpireBlack)
  document.fonts.add(interRegular)
  document.fonts.add(interSemiBold)
  document.fonts.add(interBold)
  document.fonts.add(ppHattonUltralight)
  document.fonts.add(ppHattonLight)
  document.fonts.add(ppHattonMedium)

  return document.fonts.ready
}
