declare global {
  interface Window {
    dataLayer?: DataLayer[]
  }
}

type LayerValue = string | boolean | number | (() => void) | undefined
export interface DataLayer {
  [key: string]: LayerValue
  eventCallback?: () => void
}

const shouldTrack = ['staging', 'production'].includes(
  import.meta.env.VITE_RELEASE as string
)

const camelToSnake = (str: string) =>
  str.replace(/([A-Z])/g, '_$1').toLowerCase()

export const push = (dataLayer: DataLayer) => {
  if (import.meta.env.SSR) {
    return
  }

  // myKey -> my_key
  const formattedLayer: DataLayer = {}

  Object.keys(dataLayer).forEach(key => {
    let formattedKey = key

    // Avoid UA properties
    if (
      ![
        'event',
        'eventCategory',
        'eventAction',
        'eventLabel',
        'eventCallback',
      ].includes(key)
    ) {
      formattedKey = camelToSnake(key)
    }

    formattedLayer[formattedKey] = dataLayer[key as keyof DataLayer]
  })

  if (shouldTrack) {
    window.dataLayer?.push(formattedLayer)

    if (
      !window.dataLayer ||
      !Object.prototype.hasOwnProperty.call(window.dataLayer, 'push')
    ) {
      // If callback but no GTM (or native array), trigger the callback
      formattedLayer.eventCallback?.()
    }
  } else {
    console.table(formattedLayer)
    dataLayer.eventCallback?.()
  }
}

export const pushUA = (layerUA: {
  event: string
  eventCategory: string
  eventAction: string
}) => {
  push({
    ...layerUA,
    eventLabel: window.location.pathname,
  })
}

export const pushForm = (
  status: 'start' | 'click' | 'error' | 'success',
  data?: FormData
) => {
  const layer: DataLayer = {
    formStatus: status,
  }

  switch (status) {
    case 'start':
      layer.event = 'form_log'
      if (data) {
        layer.formType = data.get('form_type') as 'project' | 'job' | 'hello'
      }
      push(layer)
      break
    case 'click':
    case 'error':
      layer.event = 'form_log'

      push(layer)
      break
    case 'success':
      if (data) {
        layer.event = 'contact_form'
        layer.formType = data.get('form_type') as 'project' | 'job' | 'hello'

        switch (layer.formType) {
          case 'project':
            layer.formBudget = (data.get('budget') as string) || 'empty'
            layer.formDeadline = (data.get('deadline') as string) || 'empty'
            break
          case 'job':
            layer.formJobType = (data.get('type') as string) || 'empty'
            layer.formJobRole = (data.get('role') as string) || 'empty'
            break
          case 'hello':
            break
          default:
        }
        layer.newsletterOptin = data.get('newsletter') === '' ? 'true' : 'false'
      } else {
        layer.event = 'form_log'
      }

      push(layer)
      break
    default:
  }
}

export const formStart = (form: HTMLFormElement) => {
  const initFormStart = () => {
    const data = new FormData(form)
    form.removeEventListener('focus', checkFocus, true)

    pushForm('start', data)
  }

  const checkFocus = (e: Event) => {
    if (e.target && [...form.elements].includes(e.target as Element)) {
      initFormStart()
    }
  }

  form.addEventListener('focus', checkFocus, true)
}
