import {
  useScroll as useScrollFn,
  UseScrollOptions,
  UseScrollReturn,
} from '@vueuse/core'
import { isClient, MaybeRef } from '@vueuse/shared'

export type ScrollCallback = (data: { x: number; y: number }) => void
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ScrollOptions extends UseScrollOptions {
  callback?: ScrollCallback
}
export type ScrollElement = MaybeRef<
  HTMLElement | SVGElement | Window | Document | null | undefined
>

const defaultWindow = /* #__PURE__ */ isClient ? window : undefined

/**
 * Overload 1: No element provided (window)
 *
 * @see https://vueuse.org/useScroll
 * @param options
 */
export function useScroll(options?: ScrollOptions): UseScrollReturn

/**
 * Overload 2: Element provided
 *
 * @see https://vueuse.org/useScroll
 * @param element
 * @param options
 */
export function useScroll(
  element: ScrollElement,
  options?: ScrollOptions
): UseScrollReturn

// Implementation signature
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useScroll(...args: any[]) {
  let element: ScrollElement
  let options: ScrollOptions

  const defaultOptions: ScrollOptions = {}

  if (typeof args[0] === 'object' || typeof args[0] === 'undefined') {
    ;[options = defaultOptions] = args
    element = defaultWindow
  } else {
    ;[element, options = defaultOptions] = args
  }

  // TODO: back to `onScroll`
  // TODO: `lock | useLock
  const { callback } = options

  if (callback) {
    delete options.onScroll

    const wrappedCallback = () => {
      const x = (element as HTMLElement).scrollLeft || -1
      const y = (element as HTMLElement).scrollTop || -1
      callback({ x, y })
    }

    options.onScroll = wrappedCallback
  }

  return useScrollFn(element, options)
}
