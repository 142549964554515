/* eslint-disable class-methods-use-this */
/* eslint-disable no-mixed-operators */
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { gsap } from 'gsap'

import { Assets } from './Assets.js'
import { IceFloorSimulationMaterial } from './customMaterials/IceFloorSimulationMaterial.js'
import { OutlineMaterial } from './customMaterials/OutlineMaterial'
import { Sim as GPUSim } from '@/components/portfolio/fbo/Sim'
import { Reflector } from './customMeshes/Reflector'
import rabbit from '@/assets/gltf/rabbit.glb?url'
import {
  Scene,
  // Fog,
  PerspectiveCamera,
  WebGLRenderer,
  sRGBEncoding,
  LinearToneMapping,
  PCFSoftShadowMap,
  Vector2,
  Raycaster,
  Clock,
  MeshToonMaterial,
  Color,
  DirectionalLight,
  PlaneBufferGeometry,
  LineDashedMaterial,
  Vector3,
  BufferGeometry,
  Line,
  BoxBufferGeometry,
  Mesh,
  Object3D,
  Material,
  BufferAttribute,
  ShaderMaterial,
} from 'three'
import { SimMaterial } from '../portfolio/fbo/SimMaterial.js'

export class World {
  private windowWidth!: number
  private windowHeight!: number
  private pixelRatio = Math.min(2, window.devicePixelRatio || 1)
  private scene = new Scene()
  private bgrColor: string
  private camera: PerspectiveCamera
  private renderer: WebGLRenderer
  private controls: OrbitControls
  private assets: Assets
  // private fog: Fog

  // hero position
  private heroAngularSpeed = 0
  private heroOldRot = 0
  private heroDistance = 0
  private heroOldUVPos = new Vector2(0.5, 0.5)
  private heroNewUVPos = new Vector2(0.5, 0.5)
  private heroSpeed = new Vector2(0, 0)
  // private heroAcc = new Vector2(0, 0)
  private targetHeroUVPos = new Vector2(0.5, 0.5)
  private targetHeroAbsMousePos = new Vector2(0, 0)
  private raycaster = new Raycaster()
  private mouse = new Vector2()
  private isJumping = false
  // private isLanding = false
  private jumpParams = { jumpProgress: 0, landProgress: 0 }

  // floor
  private floor!: Reflector
  private floorSize = 20
  private floorGeom!: PlaneBufferGeometry
  private line!: Line

  // Clock
  private clock = new Clock()
  private time = 0
  // private timeOcean = 0
  private dt = 0

  // SIM
  private floorSimMat = new IceFloorSimulationMaterial()

  // Materials
  private secColor = new MeshToonMaterial({ color: 0x332e2e }) //0x332e2e
  private primColor = new MeshToonMaterial({ color: 0x7beeff }) //0x7beeff
  private bonusColor = new MeshToonMaterial({ color: 0xff3434 }) //0xff3434

  private outlineDarkMaterial = new OutlineMaterial({
    color: 0x332e2e,
    size: 0.03,
  })
  private gpuSim: GPUSim
  // private debug: boolean
  // private outlineLightMaterial = new OutlineMaterial({
  //   color: 0x7beeff,
  //   size: 0.03,
  // })

  // Level
  // private level = -1
  private loader = new GLTFLoader()

  // Lights
  private dirLightColor = new Color(1.0, 1.0, 1.0)
  private dirLight1!: DirectionalLight

  public rabbit: Object3D | undefined
  public rabbitBody: Object3D | undefined
  private earRight: Object3D | undefined
  private earLeft: Object3D | undefined
  private tail: Object3D | undefined
  private footLeft: Object3D | undefined
  private footRight: Object3D | undefined
  private eyeLeft: Object3D | undefined
  private eyeRight: Object3D | undefined

  public carrot: Object3D | undefined
  public carrotLeaf: Object3D | undefined
  public carrotLeaf2: Object3D | undefined
  public particles1: Mesh[] = []
  public particles2: Mesh[] = []

  private rabbitBodyOutline!: Mesh
  private earRightOutline!: Mesh
  private earLeftOutline!: Mesh
  private tailOutline!: Mesh
  private carrotOutline!: Mesh
  private carrotLeaf1Outline!: Mesh
  private carrotLeaf2Outline!: Mesh
  private isExploding = false

  constructor({
    width,
    height,
    canvas,
    assets,
    background,
    renderEnabled = true,
  }: {
    width: number
    height: number
    canvas: HTMLCanvasElement
    assets: Assets
    background: string
    renderEnabled: boolean
  }) {
    if (!renderEnabled) {
      return this
    }

    this.windowHeight = height
    this.windowWidth = width
    // this.fog = new Fog(this.bgrColor, 15, 20)
    this.camera = new PerspectiveCamera(
      60,
      this.windowWidth / this.windowHeight,
      1,
      50
    )
    this.camera.position.set(0, 7, 12)

    this.renderer = new WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true,
    })
    // this.renderer.setClearColor(this.bgrColor);
    this.renderer.setPixelRatio(this.pixelRatio)
    this.renderer.setSize(this.windowWidth, this.windowHeight)
    this.renderer.outputEncoding = sRGBEncoding
    this.renderer.toneMapping = LinearToneMapping
    this.renderer.toneMappingExposure = 1
    this.renderer.shadowMap.enabled = true
    this.renderer.shadowMap.type = PCFSoftShadowMap

    // camera controls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.controls.minDistance = 0
    this.controls.maxDistance = 20
    this.controls.enabled = false

    this.assets = assets
    this.gpuSim = new GPUSim(
      this.renderer,
      1024,
      1024,
      this.floorSimMat as SimMaterial
    )
    this.bgrColor = background

    // Debug
    // const queryString = window.location.search
    // const urlParams = new URLSearchParams(queryString)
    // this.debug = Boolean(urlParams.get('debug'))

    this.loadAssets().then(() => {
      this.createLine()
      this.createLights()
      this.createFloor()
      this.createParticles()
      // this.createDat();
      this.spawnCarrot()
    })
  }

  destroy() {
    this.renderer.dispose()
    this.renderer.forceContextLoss()
    this.scene = null
    this.renderer = null
  }

  loadAssets() {
    return new Promise(resolve =>
      this.loader.load(rabbit, gltf => {
        this.rabbit = gltf.scene.getObjectByName('Rabbit')
        this.rabbitBody = gltf.scene.getObjectByName('body')
        this.earRight = gltf.scene.getObjectByName('earRight')
        this.earLeft = gltf.scene.getObjectByName('earLeft')
        this.tail = gltf.scene.getObjectByName('tail')
        this.footLeft = gltf.scene.getObjectByName('footLeft')
        this.footRight = gltf.scene.getObjectByName('footRight')
        this.eyeLeft = gltf.scene.getObjectByName('eyeLeft')
        this.eyeRight = gltf.scene.getObjectByName('eyeRight')

        this.carrot = gltf.scene.getObjectByName('carrot')
        this.carrotLeaf = gltf.scene.getObjectByName('carrotLeaf')
        this.carrotLeaf2 = gltf.scene.getObjectByName('carrotLeaf2')

        if (this.carrot) {
          this.carrot.rotation.z = 0.2
          this.carrot.rotation.x = 0.2
          ;(this.carrot as Mesh).material = this.bonusColor
          this.carrotOutline = this.addOutline(
            this.carrot as Mesh, 
            this.outlineDarkMaterial
          )

          this.carrot.traverse(object => {
            if ((object as Mesh).isMesh) {
              object.castShadow = true
              // object.receiveShadow = true;
            }
          })
          this.scene.add(this.carrot)
        }

        if (this.rabbitBody) {
          ;(this.rabbitBody as Mesh).material = this.primColor
          this.rabbitBodyOutline = this.addOutline(
            this.rabbitBody as Mesh,
            this.outlineDarkMaterial
          )
        }

        if (this.earRight) {
          ;(this.earRight as Mesh).material = this.primColor
          this.earRightOutline = this.addOutline(
            this.earRight as Mesh,
            this.outlineDarkMaterial
          )
        }

        if (this.earLeft) {
          ;(this.earLeft as Mesh).material = this.primColor
          this.earLeftOutline = this.addOutline(
            this.earLeft as Mesh,
            this.outlineDarkMaterial
          )
        }

        if (this.tail) {
          ;(this.tail as Mesh).material = this.primColor
          this.tailOutline = this.addOutline(
            this.tail as Mesh,
            this.outlineDarkMaterial
          )
        }

        this.footLeft && ((this.footLeft as Mesh).material = this.secColor)
        this.footRight && ((this.footRight as Mesh).material = this.secColor)
        this.eyeLeft && ((this.eyeLeft as Mesh).material = this.secColor)
        this.eyeRight && ((this.eyeRight as Mesh).material = this.secColor)

        if (this.carrotLeaf) {
          ;(this.carrotLeaf as Mesh).material = this.primColor
          this.carrotLeaf.castShadow = true
        }

        if (this.carrotLeaf2) {
          ;(this.carrotLeaf2 as Mesh).material = this.primColor
          this.carrotLeaf2.castShadow = true
        }

        if (this.rabbit) {
          this.rabbit.traverse(object => {
            if ((object as Mesh).isMesh) {
              object.castShadow = true
              // object.receiveShadow = true;
            }
          })

          this.scene.add(this.rabbit)
        }

        resolve(true)
      })
    )
  }

  createLights() {
    this.dirLight1 = new DirectionalLight(this.dirLightColor, 1)
    this.dirLight1.position.set(-6, 7, 3)
    this.dirLight1.castShadow = true
    this.dirLight1.shadow.mapSize.width = 1024
    this.dirLight1.shadow.mapSize.height = 1024
    this.dirLight1.shadow.camera.near = 6
    this.dirLight1.shadow.camera.far = 30
    this.dirLight1.shadow.camera.left = -20
    this.dirLight1.shadow.camera.right = 20
    this.dirLight1.shadow.camera.bottom = -20
    this.dirLight1.shadow.camera.top = 20
    this.dirLight1.shadow.bias = -0.01

    // this.dirLight1.target = this.rabbit;
    // this.scene.add( new CameraHelper( this.dirLight1.shadow.camera ) );

    this.scene.add(this.dirLight1)
  }

  calculateUnitSize(distance = this.camera.position.z) {
    const vFov = (this.camera.fov * Math.PI) / 180
    const height = 2 * Math.tan(vFov / 2) * distance
    const width = height * this.camera.aspect

    return {
      width,
      height,
    }
  }

  updateFloorColor(color: string) {
    // const parsedColor = color.includes('#')
    //   ? parseInt(color.replace('#', '0x'), 16)
    //   : color
    if (this.floor) {
      this.floor.material.uniforms.color.value = new Color(color)
    }

    return color
  }

  updateRabbitColor(color:any) {
    // const parsedColor = color.includes('#')
    //   ? parseInt(color.replace('#', '0x'), 16)
    //   : color
    if (this.rabbit) {
      const mat = new MeshToonMaterial({color:color})
      ;(this.rabbitBody as Mesh).material = mat
      ;(this.earLeft as Mesh).material = mat
      ;(this.earRight as Mesh).material = mat
      ;(this.tail as Mesh).material = mat
    }
  }

  updateCarrotColor(colorBody:any, colorLeaf1:any, colorLeaf2:any) {
    // const parsedColor = color.includes('#')
    //   ? parseInt(color.replace('#', '0x'), 16)
    //   : color
    if (this.carrot) {
      const matBody = new MeshToonMaterial({color:colorBody})
      const matLeaf1 = new MeshToonMaterial({color:colorLeaf1})
      const matLeaf2 = new MeshToonMaterial({color:colorLeaf2})
      
      ;(this.carrot as Mesh).material = matBody
      ;(this.carrotLeaf as Mesh).material = matLeaf1
      ;(this.carrotLeaf2 as Mesh).material = matLeaf2
    }
  }

  createFloor() {
    this.floorGeom = new PlaneBufferGeometry(
      this.floorSize,
      this.floorSize,
      1,
      1
    )
    this.floor = new Reflector(this.floorGeom, {
      color: this.bgrColor,
      tScratches: this.gpuSim.output.texture,
      textureWidth: 512,
      textureHeight: 512,
      noiseMap: this.assets.textures.noise,
    })
    this.floor.rotation.x = -Math.PI / 2
    this.floor.receiveShadow = true
    this.scene.add(this.floor)
  }
  createLine() {
    const material = new LineDashedMaterial({
      color: 0x7beeff,
      linewidth: 1,
      scale: 1,
      dashSize: 0.2,
      gapSize: 0.1,
    })

    const points = []
    points.push(new Vector3(0, 0.2, 0))
    points.push(new Vector3(3, 0.2, 3))

    const geometry = new BufferGeometry().setFromPoints(points)

    this.line = new Line(geometry, material)
    this.scene.add(this.line)
  }

  resize(winWidth: number, winHeight: number) {
    this.camera.aspect = winWidth / winHeight
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(winWidth, winHeight)
  }

  render() {
    this.dt = Math.min(this.clock.getDelta(), 0.3)
    this.time += this.dt
    if (this.floor) {
      this.floorSimMat.uniforms.inputTexture.value = this.gpuSim.output.texture
    }

    if (this.rabbit && this.line) {
      /*
      Elastic string simulation
      */
      const constrainUVPosX = this.constrain(
        this.targetHeroUVPos.x - 0.5,
        -0.3,
        0.3
      )
      const constrainUVPosY = this.constrain(
        this.targetHeroUVPos.y - 0.5,
        -0.3,
        0.3
      )
      this.targetHeroAbsMousePos.x = constrainUVPosX * this.floorSize
      this.targetHeroAbsMousePos.y = -constrainUVPosY * this.floorSize

      const dx = this.targetHeroAbsMousePos.x - this.rabbit.position.x
      const dy = this.targetHeroAbsMousePos.y - this.rabbit.position.z

      const angle = Math.atan2(dy, dx)
      this.heroDistance = Math.sqrt(dx * dx + dy * dy)

      const ax = dx * this.dt * 0.5
      const ay = dy * this.dt * 0.5

      this.heroSpeed.x += ax
      this.heroSpeed.y += ay

      this.heroSpeed.x *= Math.pow(this.dt, 0.005)
      this.heroSpeed.y *= Math.pow(this.dt, 0.005)

      this.rabbit.position.x += this.heroSpeed.x
      this.rabbit.position.z += this.heroSpeed.y
      const targetRot = -angle + Math.PI / 2

      if (this.heroDistance > 0.3) {
        this.rabbit.rotation.y +=
          this.getShortestAngle(targetRot - this.rabbit.rotation.y) *
          3 *
          this.dt
      }
      this.heroAngularSpeed = this.getShortestAngle(
        this.rabbit.rotation.y - this.heroOldRot
      )

      this.heroOldRot = this.rabbit.rotation.y
      if (!this.isJumping && this.earLeft && this.earRight) {
        // eslint-disable-next-line no-multi-assign
        this.earLeft.rotation.x = this.earRight.rotation.x =
          -this.heroSpeed.length() * 2
      }

      const p = new Float32Array(
        this.line.geometry.attributes.position.array.length
      )
      p[0] = this.targetHeroAbsMousePos.x
      p[2] = this.targetHeroAbsMousePos.y
      p[3] = this.rabbit.position.x
      p[4] = this.rabbit.position.y
      p[5] = this.rabbit.position.z

      this.line.geometry.setAttribute('position', new BufferAttribute(p, 3))
      this.line.geometry.attributes.position.needsUpdate = true
      this.line.computeLineDistances()

      this.heroNewUVPos = new Vector2(
        0.5 + this.rabbit.position.x / this.floorSize,
        0.5 - this.rabbit.position.z / this.floorSize
      )

      // ??
      // this.floorSimMat.time += this.dt
      this.floorSimMat.uniforms.blade1PosNew.value = this.heroNewUVPos
      this.floorSimMat.uniforms.blade1PosOld.value = this.heroOldUVPos
      this.floorSimMat.uniforms.strength.value = this.isJumping
        ? 0
        : 1 / (1 + this.heroSpeed.length() * 10)
      this.gpuSim.render()
      this.renderer.setRenderTarget(null)
      const material = this.floor.material as ShaderMaterial
      material.uniforms.tScratches.value = this.gpuSim.output.texture
      material.uniforms.heroPos.value = this.heroNewUVPos
      material.uniforms.heroHeight.value = this.rabbit.position.y
      material.uniforms.time.value += this.dt

      this.heroOldUVPos = this.heroNewUVPos.clone()

      this.carrot && (this.carrot.rotation.y += this.dt)

      this.testCollision()
    }

    // ?????
    // if (this.mixer) {
    //   this.mixer.update(this.dt)
    // }

    this.controls.update()
    this.renderer.render(this.scene, this.camera)
  }

  createParticles() {
    const bodyCount = 10
    const tailCount = 3
    const particleGeom = new BoxBufferGeometry(0.2, 0.2, 0.2, 1, 1, 1)

    let i = 0

    for (i = 0; i < bodyCount; i++) {
      const m = new Mesh(particleGeom, this.bonusColor)
      this.particles1.push(m)
      m.scale.set(0, 0, 0)
      this.scene.add(m)
    }

    for (i = 0; i < tailCount; i++) {
      const m = new Mesh(particleGeom, this.primColor)
      this.particles2.push(m)
      m.scale.set(0, 0, 0)
      this.scene.add(m)
    }
  }

  // createDat() {
  //   if (!this.debug) return

  //   // dat
  //   this.datgui = new dat.GUI()

  //   const globalFolder = this.datgui.addFolder('global')
  //   globalFolder
  //     .addColor(this, 'bgrColor')
  //     .name('Bgr')
  //     .onChange(value => {
  //       this.renderer.setClearColor(value)
  //     })
  //   globalFolder.close()

  //   // Lights
  //   const lightsFolder = this.datgui.addFolder('Lights')
  //   lightsFolder
  //     .add(this.dirLight1, 'intensity')
  //     .name('directional')
  //     .min(0)
  //     .max(5)
  //   lightsFolder
  //     .add(this.ambiantLight, 'intensity')
  //     .name('ambiant')
  //     .min(0)
  //     .max(5)
  //   lightsFolder.close()
  // }

  mouseMove(mousePos: Record<string, number>) {
    this.mouse.x = mousePos.px
    this.mouse.y = -mousePos.py
    this.floor && this.raycast()
  }

  mouseDown() {
    this.rabbit && !this.isJumping && this.jump()
  }

  raycast() {
    this.raycaster.setFromCamera(this.mouse, this.camera)
    const intersects = this.raycaster.intersectObjects([this.floor])

    if (intersects.length) {
      this.targetHeroUVPos.x = intersects[0].uv?.x || 0
      this.targetHeroUVPos.y = intersects[0].uv?.y || 0
    }
  }

  jump() {
    this.isJumping = true
    const turns = Math.floor(this.heroSpeed.length() * 5) + 1
    const jumpDuration = 0.5 + turns * 0.2
    const targetRot =
      this.heroAngularSpeed > 0 ? Math.PI * 2 * turns : -Math.PI * 2 * turns

    this.rabbitBody &&
      gsap.to(this.rabbitBody.rotation, {
        duration: jumpDuration,
        ease: 'linear.none',
        y: targetRot,
        onComplete: () => {
          this.rabbitBody && (this.rabbitBody.rotation.y = 0)
        },
      })

    if (this.earRight && this.earLeft) {
      gsap.to([this.earLeft.rotation, this.earRight.rotation], {
        duration: jumpDuration * 0.8,
        ease: 'power4.out',
        x: Math.PI / 4,
      })
      gsap.to([this.earLeft.rotation, this.earRight.rotation], {
        duration: jumpDuration * 0.2,
        delay: jumpDuration * 0.8,
        ease: 'power4.in',
        x: 0,
      })
    }

    gsap.to(this.jumpParams, {
      duration: jumpDuration * 0.5,
      ease: 'power2.out',
      jumpProgress: 0.5,
      onUpdate: () => {
        const sin = Math.sin(this.jumpParams.jumpProgress * Math.PI)
        this.rabbit && (this.rabbit.position.y = Math.pow(sin, 4) * turns)
      },
    })
    gsap.to(this.jumpParams, {
      duration: jumpDuration * 0.5,
      ease: 'power2.in',
      delay: jumpDuration * 0.5,
      jumpProgress: 1,
      onUpdate: () => {
        const sin = Math.sin(this.jumpParams.jumpProgress * Math.PI)
        this.rabbit && (this.rabbit.position.y = Math.pow(sin, 1) * turns)
      },
      onComplete: () => {
        this.rabbit && (this.rabbit.position.y = 0)
        this.jumpParams.jumpProgress = 0
        this.isJumping = false
      },
    })
  }

  getShortestAngle(v: number) {
    let a = v % (Math.PI * 2)

    if (a < -Math.PI) {
      a += Math.PI * 2
    } else if (a > Math.PI) {
      a -= Math.PI * 2
    }

    return a
  }

  constrain(v: number, vMin: number, vMax: number) {
    return Math.min(vMax, Math.max(vMin, v))
  }

  addOutline(origin: Mesh, material: Material) {
    const outline = origin.clone()

    outline.children = []
    outline.position.set(0, 0, 0)
    outline.rotation.x = 0
    outline.rotation.y = 0
    outline.rotation.z = 0
    outline.scale.set(1, 1, 1)
    outline.material = material
    origin.add(outline)

    return outline
  }

  spawnCarrot(scale: number) {
    // this.level++;
    const px = (Math.random() - 0.5) * 0.4
    const py = (Math.random() - 0.5) * 0.6
    // eslint-disable-next-line no-implicit-coercion
    const h = 0.5

    if (this.carrot) {
      if (this.floor) {
        this.carrot.position.x = px * this.floorSize
        this.carrot.position.z = py * this.floorSize
        this.carrot.position.y = h
      }

      this.carrot.scale.set(scale || 1, scale || 1, scale || 1)
      this.carrot.visible = true
      gsap.from(this.carrot.scale, {
        duration: 1.5,
        ease: 'elastic.out',
        x: 0,
        y: 0,
        z: 0,
      })
      gsap.from(this.carrot.position, {
        duration: 1.5,
        ease: 'elastic.out',
        y: 0,
      })
    }

    if (this.floor) {
      ;(this.floor.material as ShaderMaterial).uniforms.carrotPos.value =
        new Vector2(px + 0.5, -py + 0.5)
      ;(this.floor.material as ShaderMaterial).uniforms.carrotHeight.value = h
    }
  }

  testCollision() {
    if (this.isExploding) {
      return
    }

    if (this.rabbit && this.carrot) {
      const distVec = this.rabbit.position.clone()
      distVec.sub(this.carrot.position)
      const l = distVec.length()

      if (l <= 1) {
        this.carrot.visible = false
        this.explode(this.carrot.position)
      }
    }
  }

  explode(pos: Vector3, scale: number) {
    this.isExploding = true

    const p1Count = this.particles1.length
    const p2Count = this.particles2.length

    for (let i = 0; i < p1Count; i++) {
      const m = this.particles1[i]
      m.position.x = pos.x
      m.position.y = pos.y
      m.position.z = pos.z
      m.scale.set(2, 2, 2)

      gsap.to(m.position, {
        x: pos.x + (-0.5 + Math.random()) * 1.5,
        y: pos.y + (-0.5 + Math.random()) * 1.5,
        z: pos.z + (-0.5 + Math.random()) * 1.5,
        duration: 1,
        ease: 'power4.out',
      })
      gsap.to(m.scale, {
        x: 0,
        y: 0,
        z: 0,
        duration: 1,
        ease: 'power4.out',
        onComplete: () => {
          //this.spawnCarrot()
          this.isExploding = false
        },
      })
    }

    for (let i = 0; i < p2Count; i++) {
      const m = this.particles2[i]
      m.position.x = pos.x
      m.position.y = pos.y
      m.position.z = pos.z
      m.scale.set(2, 2, 2)

      gsap.to(m.position, {
        x: pos.x + (-0.5 + Math.random()) * 1.5,
        y: pos.y + (-0.5 + Math.random()) * 1.5,
        z: pos.z + (-0.5 + Math.random()) * 1.5,
        duration: 1,
        ease: 'power4.out',
      })
      gsap.to(m.scale, {
        x: 0,
        y: 0,
        z: 0,
        duration: 1,
        ease: 'power4.out',
        onComplete: () => {
          if (i == p2Count-1) this.spawnCarrot(scale)
          this.isExploding = false
        },
      })
    }
  }
}
