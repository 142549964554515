import vIceFloorShader from '../shaders/iceFloorSimulation_vert.glsl?raw'
import fIceFloorShader from '../shaders/iceFloorSimulation_frag.glsl?raw'
import { ShaderMaterial, UniformsUtils, Vector2 } from 'three'

// eslint-disable-next-line id-length
export class IceFloorSimulationMaterial extends ShaderMaterial {
  constructor(parameters = {}) {
    const defines = {}
    const uniforms = UniformsUtils.merge([
      {
        inputTexture: { type: 't', value: null },
        time: { value: 0.0 },
        blade1PosOld: { value: new Vector2(0.5, 0.5) },
        blade1PosNew: { value: new Vector2(0.5, 0.5) },
        strength: { value: 0.0 },
      },
    ])

    const vertexShader = vIceFloorShader
    const fragmentShader = fIceFloorShader

    super({
      vertexShader,
      fragmentShader,
      uniforms,
      defines,
    })

    this.setValues(parameters)
  }
}
