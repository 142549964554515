<template>
  <div ref="outerRef" :class="`cta-rounded-outer--${size}`">
    <component
      v-bind="$attrs"
      :is="svgComponentSmall"
      v-if="size === 'small' || size === 'responsive'"
    />
    <!-- <Suspense @resolve="onLargeResolve"> -->
    <component
      :is="svgComponentLarge"
      v-if="size === 'large' || size === 'big' || size === 'responsive'"
      v-bind="$attrs"
      class="large"
    />
  </div>
</template>

<script setup lang="ts">
// import gsap from 'gsap'
import { Observer } from 'gsap/Observer'
import {
  computed,
  defineAsyncComponent,
  ref,
  PropType,
  onMounted,
  onBeforeUnmount,
} from 'vue'

import { wait } from '@/utils/async'

// gsap.registerPlugin(Observer)

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  size: {
    type: String as PropType<'small' | 'large' | 'big' | 'responsive'>,
    default: 'responsive',
  },
  background: {
    type: String,
    default: 'transparent',
  },
  foreground: {
    type: String,
    default: '',
  },
})

const outerRef = ref<HTMLDivElement>()
let mouseObserver: Observer | undefined
let svgLargeEl: SVGSVGElement | null = null
let circleEl: SVGSVGElement | null = null
let iconEl: SVGSVGElement | null = null
let textEl: SVGSVGElement | null = null

const getComponent = (size: 'small' | 'large') => {
  const filename = `${props.name}-${size}`

  return defineAsyncComponent(() => import(`./cta-rounded/${filename}.vue`))
}

const svgComponentSmall = computed(() => getComponent('small'))
const svgComponentLarge = computed(() => getComponent('large'))

const onMove = (o: Observer) => {
  if (!iconEl || !textEl) {
    return
  }

  const rect = (svgLargeEl as SVGSVGElement).getBoundingClientRect()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { x: offsetX, y: offsetY } = o.event as any

  /* eslint-disable no-mixed-operators */
  const x = offsetX - rect.left - rect.width / 2
  const y = offsetY - rect.top - rect.height / 2
  /* eslint-enable no-mixed-operators */

  const icon = {
    x: x * 0.5,
    y: y * 0.5,
  }
  const text = {
    x: x * 0.2,
    y: y * 0.2,
  }

  iconEl.style.transform = `translate(${icon.x}px, ${icon.y}px) rotateY(${
    icon.x
  }deg) rotateX(${-icon.y}deg) scale(1.3)`
  textEl.style.transform = `translate(${text.x}px, ${text.y}px) rotateY(${
    text.x * 0.75
  }deg) rotateX(${-text.y * 0.75}deg) scale(0.9)`
}

const onHoverEnd = async () => {
  if (!iconEl || !textEl) {
    return
  }

  await wait(0.1)
  iconEl.style.transform = 'translate(0, 0) scale(1)'
  textEl.style.transform = 'translate(0, 0) scale(1)'
}

const onLargeResolve = () => {
  setTimeout(() => {
    if (!outerRef.value) {
      return
    }
    svgLargeEl = outerRef.value.querySelector('.large')

    if (!svgLargeEl) {
      return
    }

    circleEl = svgLargeEl.querySelector('.circle')
    textEl = svgLargeEl.querySelector('.text-outer')
    iconEl = svgLargeEl.querySelector('.icon')

    if (circleEl) {
      mouseObserver = Observer.create({
        target: circleEl,
        type: 'pointer',
        onMove,
        onHoverEnd,
      })
    }
  }, 1000)
}

onMounted(() => {
  if (!outerRef.value) {
    return
  }

  if (props.size === 'large' || props.size === 'responsive') {
    // Suspense callback does not fire with multiple component on page
    onLargeResolve()
  }

  if (props.background !== '') {
    outerRef.value.style.setProperty('--bg', props.background)
  }

  if (props.foreground !== '') {
    outerRef.value.style.setProperty('--fg', props.foreground)
  }
})

onBeforeUnmount(() => {
  mouseObserver?.kill()
})
</script>

<style lang="scss" scoped>
$s-small: 8rem;
$s-large: 12rem;
$s-big: 18rem;

[class*='cta-rounded-outer--'] {
  --fg: var(--c-foreground);
  --bg: var(--c-background);

  pointer-events: all;
  user-select: none;

  :deep(svg) {
    cursor: pointer;
    fill: var(--fg);

    .text-outer,
    .text,
    .icon {
      transform-origin: center;
      transition: transform 1s cubic-bezier(0.22, 1, 0.36, 1);
      pointer-events: none;
    }

    .circle {
      fill: var(--bg);
      transform-origin: center;
      stroke: var(--fg);
      stroke-width: 1;
      stroke-dasharray: 490 490;
      stroke-dashoffset: 490;
      transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
    }

    .inner-circle {
      stroke: var(--fg);
      fill: none;
    }

    .text {
      font-family: $ff-default;
    }

    .text-container {
      transform-origin: center;
      animation-duration: 30s;
      animation-iteration-count: infinite;
      animation-name: spin;
      animation-timing-function: linear;
    }

    .text-background {
      stroke: var(--bg);
      stroke-width: 5px;
      fill: none;
    }
  }

  :deep(svg:hover) {
    .circle {
      stroke-dashoffset: 0;
      transform: rotate(90deg);
    }

    // Does not work with spin animation
    // g {
    //   transform: scale(0.5);
    // }
  }
}

[class*='cta-rounded-outer--'][class*='--small'] :deep(svg),
[class*='cta-rounded-outer--'][class*='--responsive'] :deep(svg:first-child) {
  width: $s-small;
  height: $s-small;
}

[class*='cta-rounded-outer--'][class*='--large'] :deep(svg),
[class*='cta-rounded-outer--'][class*='--responsive'] :deep(svg:last-child) {
  width: $s-large;
  height: $s-large;
}

[class*='cta-rounded-outer--'][class*='--big'] :deep(svg) {
  @include mq($until: m) {
    width: $s-big;
    height: $s-big;

    @include mq($until: l) {
      width: $s-small;
      height: $s-small;
    }
  }
}

[class*='cta-rounded-outer--'][class*='--responsive'] {
  @include mq($until: m) {
    :deep(svg:last-child) {
      display: none;
    }
  }

  @include mq($from: m) {
    :deep(svg:first-child) {
      display: none;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
