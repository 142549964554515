<template>
  <div class="input" data-input>
    <input
      v-bind="$attrs"
      :id="id"
      ref="input"
      :required="required"
      :value="modelValue"
      :placeholder="placeholder || label"
      class="input__field"
      :class="{ 'is-active': modelValue.length || placeholder }"
      @input="emit('update:modelValue', $event.target.value)"
    />

    <label v-if="label" :for="id" class="input__label form-label">
      {{ label }}
      <sup v-if="!required" class="input__label__small info-label">
        {{ t('form-optional') }}
      </sup>
    </label>
    <p class="input__error">{{ t('form-invalid') }}</p>
  </div>
</template>

<script setup lang="ts">
import { useChromeStore } from '@/stores/chrome'

defineProps({
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  modelValue: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['update:modelValue'])
const { t } = useChromeStore()
</script>

<style lang="scss">
.input__error {
  @extend %fw-bold;

  position: absolute;
  display: none;
  padding: 0.3rem 1rem;
  color: var(--c-background);
  font-size: 1.5rem;
  background-color: var(--c-foreground);
  border-radius: 8px;
  transform: translateY(5px);

  .has-error & {
    display: block;
  }
}
</style>

<style lang="scss" scoped>
.input {
  position: relative;
}

.input__field {
  width: 100%;
  margin-bottom: 0;
  padding: 0.5rem 0;
  color: var(--c-foreground);
  font-size: 1.6rem;
  line-height: 1;
  background: transparent;
  border: 0;
  border-bottom: 1px solid var(--c-foreground);

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px var(--c-background) inset;
    -webkit-text-fill-color: var(--c-foreground);
  }

  &:disabled:-webkit-autofill {
    -webkit-text-fill-color: var(--c-foreground);
  }

  &::placeholder {
    opacity: 0;
  }

  .has-error &,
  &:not(:focus):not(:placeholder-shown):invalid {
    border-bottom: 3px solid var(--c-foreground);
  }

  &:focus + label,
  &.is-active + label {
    transform: translateY(-100%);
  }

  &:not(:focus):not(:placeholder-shown):invalid ~ .input__error {
    display: block;
  }
}

.input__label {
  @include center-y;

  display: flex;
  align-items: center;
  height: 150%;
  pointer-events: none;
  transition: transform 0.2s;
}

.input__label__small {
  margin: 0 0 0.5rem 1.5rem;
  opacity: 0.5;
}

.input__error {
  .has-error & {
    display: block;
  }
}
</style>
