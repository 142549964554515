/**
 * https://realfavicongenerator.net/
 *
 * @output (06/2022)
 * <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png">
 * <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png">
 * <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png">
 * <link rel="manifest" href="/favicon/site.webmanifest">
 * <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#0317ee">
 * <link rel="shortcut icon" href="/favicon/favicon.ico">
 * <meta name="msapplication-TileColor" content="#0317ee">
 * <meta name="msapplication-config" content="/favicon/browserconfig.xml">
 * <meta name="theme-color" content="#ffffff">
 */

import { HeadAttrs } from '@vueuse/head'

const colors = {
  tab: '#bb984d',
  tile: '#bb984d',
  theme: '#ffffff',
}

export default {
  link: [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/favicon/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon/favicon-16x16.png',
    },
    {
      rel: 'manifest',
      href: '/favicon/site.webmanifest',
    },
    {
      rel: 'mask-icon',
      href: '/favicon/safari-pinned-tab.svg',
      color: colors.tab,
    },
    {
      rel: 'shortcut icon',
      href: '/favicon/favicon.ico',
    },
  ] as HeadAttrs[],
  meta: [
    {
      name: 'msapplication-TileColor',
      content: colors.tile,
    },
    {
      name: 'msapplication-config',
      content: '/favicon/browserconfig.xml',
    },
    // {
    //   name: 'theme-color',
    //   content: colors.theme,
    // },
  ],
}
