<template>
  <div ref="containerRef" class="page-transition" aria-hidden="true"></div>
  <transition
    :css="false"
    :mode="mode"
    @leave="onLeave"
    @enter="onEnter"
    @before-leave="onBeforeLeave"
  >
    <slot></slot>
  </transition>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { CustomEase } from 'gsap/CustomEase'
import { ref, PropType } from 'vue'
import { useRoute } from 'vue-router'

import { hasMotion } from '@/core/prefers'
import { useUiStore } from '@/stores/ui'
import { wait } from '@/utils/async'
import { logger } from '@/utils/logger'

gsap.registerPlugin(CustomEase)

defineProps({
  mode: {
    type: String as PropType<'default' | 'out-in' | 'in-out' | undefined>,
    default: 'out-in',
  },
})
const emit = defineEmits(['before-leave'])

const route = useRoute()

const pageInEase = CustomEase.create('custom', 'M0,0,C0.48,0,0,1,1,1')
const EASES = {
  container: {
    in: pageInEase,
    out: CustomEase.create('custom', 'M0,0,C0,0.71,0.58,1,1,1'),
  },
  logo: {
    in: pageInEase,
  },
}

const ui = useUiStore()
const containerRef = ref()

let tlIn: gsap.core.Timeline | undefined
let tlOut: gsap.core.Timeline | undefined

const initTlIn = () => {
  tlIn = gsap.timeline()

  tlIn.add(() => {
    ui.hasTransition = 'page'
  })

  if (containerRef.value) {
    tlIn.to(
      containerRef.value,
      {
        opacity: 1,
        ease: EASES.container.in,
        duration: 0.6,
      },
      'start'
    )
  }
}

const initTlOut = () => {
  tlOut = gsap.timeline()

  if (containerRef.value) {
    tlOut.to(containerRef.value, {
      opacity: 0,
      // ease: EASES.container.out,
      ease: 'power2.out',
      duration: 0.6,
    })

    // Notify App that page transition animation is over slightly before it actually finishes.
    // When using strong easing, if subsequent animations are waiting to play,
    // it will feel more fluid than waiting for actual end of TL.
    tlOut.add(() => {
      ui.hasTransition = 'none'
    }, '-=0.4')
  }
}

const onLeave = async (el: Element, done: () => void) => {
  try {
    // if (hasMotion.value && ui.hasTransition === 'page') {
    if (hasMotion.value && route.meta.hasTransition !== false) {
      tlOut && tlOut.isActive() && tlOut.kill()

      ui.hasTransition = 'page'
      ui.toggleScroll(false)

      !tlIn && initTlIn()
      await tlIn!.invalidate().restart().then()

      window.scrollTo(0, 0)
      await wait(0)
    }
  } catch (error) {
    logger.error(error)
  }

  done()
}
const onEnter = async (el: Element, done: () => void) => {
  try {
    // if (motionOK.value && animatePageTransition.value) {
    if (hasMotion.value && route.meta.hasTransition !== false) {
      tlIn && tlIn.isActive() && tlIn.kill()
      !tlOut && initTlOut()

      await tlOut!.invalidate().restart().then()

      if (route.meta.isLocked !== true) {
        ui.toggleScroll(true)
      }
    }
  } catch (error) {
    logger.error(error)
  }

  done()
}

const onBeforeLeave = () => {
  emit('before-leave')
}
</script>

<style lang="scss" scoped>
.page-transition {
  position: fixed;
  z-index: $header-z-index - 1;
  top: 0;
  right: 0;
  left: 0;
  // display: grid;
  // align-items: center;
  // justify-items: center;
  // height: calc(100 * var(--vh));
  height: 100vh;
  color: var(--c-foreground);
  background-color: var(--c-background);
  opacity: 0;
  pointer-events: none;
  // clip-path: inset(0 100% 0 0);
  /* stylelint-disable-next-line
      order/properties-order,
      declaration-block-no-duplicate-properties,
      declaration-no-important */
  // display: none !important;
}

.page-transition__logo {
  display: none;
  font-size: 6rem;
  font-weight: 700;
  line-height: 1.15;
  will-change: transform;
}
</style>
