import { BackSide, Color, ShaderMaterial, UniformsUtils } from 'three'
import vOutlineShader from '../shaders/outline_vert.glsl?raw'
import fOutlineShader from '../shaders/outline_frag.glsl?raw'

export class OutlineMaterial extends ShaderMaterial {
  constructor(parameters = {}) {
    const defines = {}
    const uniforms = UniformsUtils.merge([
      {
        color: { value: new Color(0x000000) },
        size: { type: 'f', value: 0.02 },
        time: { type: 'f', value: 0 },
      },
    ])

    const vertexShader = vOutlineShader
    const fragmentShader = fOutlineShader

    super({
      vertexShader,
      fragmentShader,
      uniforms,
      defines,
      side: BackSide,
    })

    this.setValues(parameters)
  }

  set color(v) {
    this.uniforms.color.value = v
  }

  get color() {
    return this.uniforms.color.value
  }

  set size(v) {
    this.uniforms.size.value = v
  }

  get size() {
    return this.uniforms.size.value
  }

  set time(v) {
    this.uniforms.time.value = v
  }

  get time() {
    return this.uniforms.time.value
  }
}
