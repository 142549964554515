import UAParser from 'ua-parser-js'

/**
 * User Agent infos
 */
let ua: UAParser | null = null

if (import.meta.env && import.meta.env.SSR === false) {
  ua = new UAParser()
}

export { ua }

/**
 * URLs, segments, endpoints
 */
export const endpointDefault = 'pages'

/**
 * API URLs
 */
export const getApiUrl = () =>
  `${import.meta.env.VITE_API_DOMAIN}${import.meta.env.VITE_API_PATH}`
export const getThumborUrl = () =>
  `${import.meta.env.VITE_API_DOMAIN}${import.meta.env.VITE_THUMBOR_PATH}`

/**
 * GTM ID
 */
export const gtmId = 'GTM-K664H6X'
export const useGtm = true

/**
 * Google Site Verification
 */
// export const googleSite = 'xxx_yyyzzzzzzz'
export let googleSite: string

/**
 * Options
 */
export const emoji = '🏰'
export const darkModeOptions = {
  selector: 'html',
  attribute: 'color-scheme',
  valueDark: 'dark',
  valueLight: 'light',
}

// Layout
export const gridCols = 12
export const scrollbarWidthDefault = 6

// Icons
export const iconViewbox = '0 0 24 24'
export const iconRegistered: Record<
  string,
  { symbol: string; viewbox?: string }
> = {
  arrow: { symbol: 'arrows-large-right' },
  download: { symbol: 'icons-download' },
} // as const
