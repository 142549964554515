import { useWindowSize, UseWindowSizeReturn } from '@vueuse/core'
import { isClient } from '@vueuse/shared'
import { computed, ComputedRef, watch } from 'vue'

import { scrollbarWidthDefault } from '@/config/app'
import { logger } from '@/utils/logger'

const viewport = useWindowSize({
  // Fix device toolbar in Chrome with wrong viewport size
  // https://github.com/vueuse/vueuse/blob/main/packages/core/useWindowSize/index.ts#L44
  // includeScrollbar: import.meta.env.VITE_RELEASE !== 'development',
  includeScrollbar: false,
}) as unknown as UseWindowSizeReturn & {
  ratio: ComputedRef<number>
}

/**
 * Add ratio ref to windowSize object
 * @see https://vueuse.org/core/windowSize/
 */
viewport.ratio = computed(() => viewport.width.value / viewport.height.value)

export function useViewport() {
  return viewport
}

/**
 * Manage viewport units with CSS custom property
 * @see // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
const setVh = () => {
  const vh = viewport.height.value * 0.01

  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const getScrollBarWidth = () => {
  if (scrollbarWidthDefault) {
    return scrollbarWidthDefault
  }

  const inner = document.createElement('p')
  inner.style.width = '100%'
  inner.style.height = '200px'

  const outer = document.createElement('div')
  outer.style.position = 'absolute'
  outer.style.top = '0px'
  outer.style.left = '0px'
  outer.style.visibility = 'hidden'
  outer.style.width = '200px'
  outer.style.height = '150px'
  outer.style.overflow = 'hidden'

  outer.appendChild(inner)

  document.body.appendChild(outer)
  const w1 = inner.offsetWidth

  outer.style.overflow = 'scroll'

  let w2 = inner.offsetWidth

  if (w1 === w2) {
    w2 = outer.clientWidth
  }

  document.body.removeChild(outer)

  return w1 - w2
}

const setScrollBarWidth = () => {
  const scrollbarWidth = getScrollBarWidth()

  document.documentElement.style.setProperty(
    '--scrollbar-width',
    `${scrollbarWidth}px`
  )
}

if (isClient) {
  setVh()
  setScrollBarWidth()

  logger.log(
    '[viewport] init',
    document.documentElement.style.getPropertyValue('--vh'),
    document.documentElement.style.getPropertyValue('--scrollbar-width')
  )

  watch(
    () => ({
      width: viewport.width.value,
      height: viewport.height.value,
      ratio: viewport.ratio.value,
    }),
    setVh
  )
}

export { viewport }
