<template>
  <component :is="component" :url="url" :to="to" v-bind="$attrs">
    <slot />
  </component>
</template>

<script lang="tsx">
/* eslint-disable vue/one-component-per-file */

import { defineComponent, h } from 'vue'
import { useI18n } from 'vue-i18n'

// Render functions
const props = {
  url: { type: String },
  to: { type: [Object, String] },
}

const ActionOutside = defineComponent({
  name: 'ActionOutside',
  props,
  setup: (props, ctx) => () =>
    h(
      <a
        href={props.url || (props.to as string)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {ctx.slots.default?.()}
      </a>
    ),
})

const ActionRouterLink = defineComponent({
  name: 'ActionRouterLink',
  props,
  setup: (props, ctx) => () => {
    let { to } = props

    if (typeof props.to === 'object' && props.to) {
      const i18n = useI18n()
      to = {
        name: props.to.name,
      }
    }

    return h(
      <router-link to={props.url || to}> {ctx.slots.default?.()} </router-link>
    )
  },
})

export default defineComponent({
  name: 'Action',
  props,

  setup(props) {
    const to =
      typeof props.to === 'string' ? (props.to as string) : props.to?.path
    const path = props.url || to
    const isAnchor = path && /^(http|#|tel|mailto)/.test(path)

    let component = ActionRouterLink

    if (isAnchor) {
      component = ActionOutside
    }

    return {
      component,
    }
  },
})
</script>
