<template>
  <div class="footer wrapper">
    <div class="footer-info">
      <div class="footer-links">
        <strong class="footer-links__title">{{ chrome.address.name }}</strong>
        <address>
          <a
            :href="chrome.address.gmapsUrl"
            target="_blank"
            :title="chrome.address.name"
            @click="onContactClick('maps', chrome.address.gmapsUrl)"
          >
            <div>{{ chrome.address.street }} {{ chrome.address.number }},</div>
            <div>
              {{ chrome.address.zipcode }} {{ chrome.address.city }}<br />
              {{ chrome.address.country }}
            </div>
          </a>
          <a
            :href="chrome.address.phone?.value"
            class="footer-links__phone"
            @click="onContactClick('telephone', chrome.address.phone?.value)"
          >
            {{ chrome.address.phone?.label }}
          </a>
        </address>
      </div>

      <nav class="footer-nav">
        <ol>
          <li v-for="link in chrome.footer" :key="link.url">
            <GAction
              :url="link.url"
              :target="link.target"
              class="footer-nav__item"
            >
              {{ link.label }}
            </GAction>
          </li>
        </ol>
      </nav>

      <div class="footer-misc">
        <nav class="footer-experiments">
          <router-link
            :to="`/${chrome.language}/404/`"
            :title="chrome.t('footer-puzzle')"
            :aria-label="chrome.t('footer-puzzle')"
            class="footer-experiments__item"
          >
            <SvgSprite symbol="icons-puzzle" size="15" />
          </router-link>
          <router-link
            :to="
              chrome.language === 'en'
                ? `/${chrome.language}/thank-you/`
                : `/${chrome.language}/merci/`
            "
            :title="chrome.t('footer-rabbit')"
            :aria-label="chrome.t('footer-rabbit')"
            class="footer-experiments__item"
          >
            <SvgSprite symbol="icons-rabbit" size="18" />
          </router-link>
          <router-link
            :to="`/${chrome.language}/#castle`"
            :title="chrome.t('footer-castle')"
            :aria-label="chrome.t('footer-castle')"
            class="footer-experiments__item"
          >
            <SvgSprite symbol="icons-castle" size="20" />
          </router-link>
        </nav>

        <nav class="footer-legals">
          <ol v-if="chrome.legals" class="">
            <li v-for="nav in chrome.legals" :key="nav.label">
              <GAction
                :url="nav.url"
                :target="nav.target"
                class="footer-legals__link"
              >
                {{ nav.label }}
              </GAction>
            </li>
            <li key="cookie-settings">
              <button
                class="footer-legals__link"
                @click.prevent="showCookieSettings"
              >
                {{ chrome.t('footer-cookies') }}
              </button>
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="footer-cta">
      <Socials :links="chrome.socials" class="footer-cta__socials" />
      <Newsletter class="footer-cta__newsletter" />
    </div>
  </div>
</template>

<script setup lang="ts">
import Newsletter from '@/components/chrome/Newsletter.vue'
import Socials from '@/components/ui/Socials.vue'
import { useChromeStore } from '@/stores/chrome'
import { push } from '@/utils/tracking'

const chrome = useChromeStore()

const onContactClick = (type: string, url: string) => {
  push({
    event: 'contact_cta_links',
    linkUrl: url,
    contactLinkType: type,
  })
}

const showCookieSettings = () => {
  window.__cookieBar__ && window.__cookieBar__.edit()
}
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  z-index: 10;
  margin-bottom: 4rem;
  font-size: 1.4rem;
  text-align: center;
  background-color: var(--c-background);
  border-top: 1px solid var(--c-foreground);

  ol {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a,
  a:visited {
    color: var(--c-foreground);

    &:hover {
      filter: brightness(1.2);
    }
  }

  @include mq(xxl) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 6rem;
    padding-top: 8rem;
    text-align: left;
  }
}

.footer-info {
  margin-bottom: 4rem;
  padding: 4rem 0;
  border-bottom: 1px solid var(--c-foreground);

  @include mq(s) {
    display: flex;
    justify-content: space-around;
    margin-bottom: 6rem;
    padding-top: 8rem;
    padding-bottom: 6rem;

    + * {
      flex-basis: 30%;
    }
  }

  @include mq(xxl) {
    justify-content: normal;
    flex-basis: col(7);
    margin-bottom: 0rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    border-bottom: 0;

    > * {
      flex-basis: auto;
    }

    > *:not(:last-child) {
      margin-right: 8rem;
    }
  }
}

.footer-nav,
.footer-links {
  margin-bottom: 4rem;

  @include mq(s) {
    margin-bottom: 0;
  }
}

.footer-links {
  letter-spacing: 0.5px;

  address {
    font-style: normal;
  }
}

.footer-links__title {
  display: block;
  margin-bottom: 1.5rem;
}

.footer-links__phone {
  display: block;
  margin-top: 1.5rem;
}

.footer-nav__item {
  @extend %fw-semi;

  text-transform: uppercase;
}

.footer-experiments {
  margin-bottom: 1rem;

  *:not(:last-child) {
    margin-right: 1rem;
  }
}

.footer-experiments__item {
  fill: var(--c-foreground);

  &:hover {
    filter: brightness(1.2);
  }

  &:last-child {
    margin-left: -2px;
  }
}

.footer-legals {
  text-transform: uppercase;

  *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.footer-legals__link {
  padding: 0;
  color: var(--c-foreground);
  text-transform: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.footer-cta {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq(xxl) {
    align-items: flex-end;
  }
}

.footer-cta__socials {
  margin-bottom: 2rem;
}
</style>
