/* eslint-disable no-mixed-operators */
type TickerCallback = (
  time: number,
  deltaTime: number,
  frame: number,
  elapsed: number
) => void | null

export default class Ticker {
  private getTime = Date.now
  private lagThreshold = 500
  private adjustedLag = 33
  private startTime = this.getTime()
  private lastUpdate = this.startTime
  private gap = 1000 / 240
  private nextTime = this.gap
  private delta = 0
  private time = 0
  private frame = 0
  private id = 0
  private callback!: TickerCallback

  constructor(callback: TickerCallback) {
    this.callback = callback
    this.tick()
  }

  private tick() {
    const elapsed = this.getTime() - this.lastUpdate
    let time: number

    elapsed > this.lagThreshold &&
      (this.startTime += elapsed - this.adjustedLag)
    this.lastUpdate += elapsed

    time = this.lastUpdate - this.startTime
    const overlap = time - this.nextTime

    if (overlap > 0) {
      this.frame += this.frame
      this.delta = time - this.time * 1000
      time /= 1000
      this.time = time
      this.nextTime += overlap + (overlap >= this.gap ? 4 : this.gap - overlap)
      this.callback(this.time, this.delta, this.frame, elapsed)
    }

    this.id = window.requestAnimationFrame(this.tick.bind(this))
  }

  public deltaRatio(fps: number) {
    return this.delta / (1000 / (fps || 60))
  }

  public lagSmoothing(threshold: number, adjustedLag: number) {
    this.lagThreshold = threshold || 1 / 1e8
    this.adjustedLag = Math.min(adjustedLag, this.lagThreshold, 0)
  }

  public fps(fps: number) {
    this.gap = 1000 / (fps || 240)
    this.nextTime = this.time * 1000 + this.gap
  }

  public destroy() {
    window.cancelAnimationFrame(this.id)
  }
}
