/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios'
import { defineStore } from 'pinia'
import { HeadObjectPlain } from '@vueuse/head'

import { BaseContent, Languages } from '@/types'

export type Content = BaseContent
export interface Resource {
  content: Content
}

export interface ResourceHead extends HeadObjectPlain {
  description?: string
}

export interface ResourceSingle extends Resource {
  id: number
  head: ResourceHead
  languages: Languages
  template: string
  slug: string
  type: string
  url: string

  i18n?: Record<string, any>
}

export type ResourceError = Error | AxiosError

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ResourceState extends ResourceSingle {}

const defaultState = (): ResourceSingle => ({
  content: {} as Content,
  id: 0,
  languages: {} as Languages,
  head: {} as ResourceHead,
  slug: 'home',
  template: 'homepage',
  type: 'homepage_decorator',
  url: '/',
})

export const useResourceStore = defineStore('resource', {
  state: defaultState,
})
