import { useDark } from '@vueuse/core'
import { computed, ref } from 'vue'

import { darkModeOptions } from '@/config/app'

export const prefers = {
  reducedMotion: 'no-preference',
  colorScheme: 'light',
  init() {
    this.reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)')
      .matches
      ? 'reduce'
      : 'no-preference'
    this.colorScheme = window.matchMedia('(prefers-color-scheme: light)')
      .matches
      ? 'light'
      : 'dark'
    hasMotion.value = this.reducedMotion !== 'reduce'
  },
}

export const hasMotion = ref(true)
export const isDark = useDark(darkModeOptions)
export const isLight = computed(() => !isDark.value)
