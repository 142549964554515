import { ShaderMaterial, UniformsUtils, Vector2 } from 'three'

import vertexShader from '@/components/portfolio/shaders/pushSimulation.vert?raw'
import fragmentShader from '@/components/portfolio/shaders/pushSimulation.frag?raw'

export class SimMaterial extends ShaderMaterial {
  constructor() {
    const defines = {}
    const uniforms = UniformsUtils.merge([
      {
        time: { value: 0.0 },
        inputTexture: { type: 't', value: null },
        mousePos: { type: 'v2', value: new Vector2(0.5, 0.5) },
      },
    ])

    super({
      vertexShader,
      fragmentShader,
      uniforms,
      defines,
    })

    this.setValues({})
  }
  set time(v) {
    this.uniforms.time.value = v
  }
  get time() {
    return this.uniforms.time.value
  }
  set mousePos(v) {
    this.uniforms.mousePos.value = v
  }
  get mousePos() {
    return this.uniforms.mousePos.value
  }
}
