/* eslint-disable id-length */
/* eslint-disable no-mixed-operators */
import Base from './base'
import CannonDebugRenderer from './cannonDebugRenderer'
import { gsap } from 'gsap'
import { Observer } from 'gsap/Observer'
import isMobile from 'ismobilejs'
import {
  Vector3,
  Group,
  PCFSoftShadowMap,
  AmbientLight,
  MeshPhongMaterial,
  DoubleSide,
  MathUtils,
  Object3D,
  DirectionalLight,
} from 'three'
import {
  Trimesh,
  Body,
  Vec3,
  Sphere,
  Plane,
  World,
  Material,
  ContactMaterial,
  Box,
} from 'cannon-es'

import maze from '@/assets/gltf/maze.glb?url'
import { Sensor } from './gyro'
import { World as RabbitWorld } from '@/components/rabbit/World'
import { Assets } from '../rabbit/Assets'

gsap.registerPlugin(Observer)

export class Maze extends Base {
  public isActive = true
  private isMobile = isMobile()
  private cameraPosition = new Vector3(0, 8, this.isMobile.any ? 0 : 3)
  private rotationVec = new Vector3(0, 0, 0)
  private gyroRotationVec = new Vector3(0, 0, 0)
  // private dummyXVector = new Vec3(1, 0, 0)
  // private dummyZVector = new Vec3(0, 0, 1)
  private group = new Group()
  // private quatX = new Quaternion()
  // private quatZ = new Quaternion()
  // private quatXMaze = new Quaternion()
  // private quatZMaze = new Quaternion()

  private rabbitWorld!: RabbitWorld
  private gyroSensor = new Sensor()
  private world = new World()
  private physicsMaterial!: Material
  private worldFriction = 1.0
  private cannonDebugRenderer!: CannonDebugRenderer
  private cameraContainer = new Group()
  private maze!: Group
  private mazeBody!: Body
  private sphereBody!: Body
  private topPlaneBody!: Body
  private planeBody!: Body
  private rabbitModel!: Object3D
  private carrot!: Object3D
  private carrotRotationSpeed = 0.02
  private carrotCollisionRadius = 0.4
  private carrotScale = 0.5
  private carrotYPosition = 0.5
  private carrotsPositions: Object3D[] = []
  private currentCarrotPosition!: Vector3
  private observer!: Observer

  private initialTiltAmountOnMobile = 0
  private rotationSpeed = 2
  private gravityMultiplier = 25
  private mazeRotationMultiplier = 3.5
  private maxMazeRotation = 0.3

  constructor(canvas: HTMLCanvasElement) {
    super(canvas)

    this.renderer.shadowMap.enabled = true
    this.renderer.shadowMap.type = PCFSoftShadowMap

    this.setupRabbit().then(() => {
      this.ready()
    })
  }

  setupRabbit() {
    this.rabbitWorld = new RabbitWorld({
      width: 0,
      height: 0,
      canvas: {} as HTMLCanvasElement,
      assets: {} as Assets,
      renderEnabled: false,
      background: '',
    })

    return this.rabbitWorld.loadAssets().then(() => {
      this.rabbitWorld.createParticles()
      const particles = this.rabbitWorld.particles1.concat(
        this.rabbitWorld.particles2
      )

      particles.forEach(p => this.scene.add(p))
    })
  }

  ready() {
    this.setupWorldPhysics()
    this.setupCamera()
    this.addLights()
    this.loadMaze().then(() => {
      this.addObjects()
      this.addPhysicsObjects()
      this.addEvents()
    })

    addEventListener('orientationchange', () => {
      this.resetRotation()
    })
  }

  resetRotation() {
    this.world.gravity.z = 0
    this.world.gravity.x = 0

    this.cameraContainer.rotation.set(0, 0, 0)
    this.initialTiltAmountOnMobile = this.gyroSensor.alpha
  }

  setupWorldPhysics() {
    this.world.gravity.set(0, -10, 0)
    this.world.quatNormalizeSkip = 0
    this.world.quatNormalizeFast = false
    this.world.defaultContactMaterial.contactEquationStiffness = 1e9
    this.world.defaultContactMaterial.contactEquationRelaxation = 4
    // this.world.solver.iterations = 20
    this.world.broadphase.useBoundingBoxes = true
    // const solver = new GSSolver()
    // solver.iterations = 20
    // solver.tolerance = 0.01
    // this.world.solver = new SplitSolver(solver)
    this.cannonDebugRenderer = new CannonDebugRenderer(this.scene, this.world)

    this.physicsMaterial = new Material('slipperyMaterial')
    const physicsContactMaterial = new ContactMaterial(
      this.physicsMaterial,
      this.physicsMaterial,
      {
        friction: this.worldFriction,
        restitution: 0.3,
      }
    )
    this.world.addContactMaterial(physicsContactMaterial)
  }

  setupCamera() {
    this.camera.position.copy(this.cameraPosition)
    this.cameraContainer.add(this.camera)
    this.scene.add(this.cameraContainer)
  }

  addLights() {
    /*
    this.scene.add(new AmbientLight(0xff4552, 1))
    const spotLight = new SpotLight(0x7beeff, 2)
    spotLight.position.set(0, 8, 1)
    spotLight.lookAt(new Vector3(0, 0, 0))
    spotLight.penumbra = 1
    spotLight.decay = 2
    spotLight.distance = 200
    spotLight.castShadow = true
    spotLight.shadow.mapSize.width = 4096
    spotLight.shadow.mapSize.height = 4096
    spotLight.shadow.camera.near = 1
    spotLight.shadow.camera.far = 200
    spotLight.shadow.focus = 1
    spotLight.shadow.bias = -0.001
    this.scene.add(spotLight)
    */
    this.scene.add(new AmbientLight(0xffffff, 0.9))
    const dirLight1 = new DirectionalLight(0xffffff, 1)
    dirLight1.position.set(-6, 7, -3)
    dirLight1.castShadow = true
    dirLight1.shadow.mapSize.width = 4096
    dirLight1.shadow.mapSize.height = 4096
    dirLight1.shadow.camera.near = 6
    dirLight1.shadow.camera.far = 30
    dirLight1.shadow.camera.left = -20
    dirLight1.shadow.camera.right = 20
    dirLight1.shadow.camera.bottom = -20
    dirLight1.shadow.camera.top = 20
    dirLight1.shadow.bias = -0.001
    this.scene.add(dirLight1)
  }

  destroy() {
    this.renderer.dispose()
    this.renderer.forceContextLoss()
    this.gyroSensor.destroy()
    this.observer.kill()
    this.world.removeBody(this.sphereBody)
    this.world.removeBody(this.mazeBody)
    this.world = null
    this.scene = null
    this.renderer = null
  }

  loadMaze() {
    return new Promise(resolve =>
      this.gltfLoader.load(maze, data => {
        this.maze = data.scene
        this.maze.traverse(obj => {
          if (obj.name.includes('carrot')) {
            this.carrotsPositions.push(obj)
          }

          if (obj.type === 'Mesh') {
            const material = new MeshPhongMaterial({
              map: obj.material.map,
              side: DoubleSide,
              shininess: 0,
            })
            obj.material = material
            obj.castShadow = true
            obj.receiveShadow = true
            const vertices = obj.geometry.clone().toNonIndexed().attributes
              .position.array
            const indices = Object.keys(vertices).map(Number)
            const mesh = new Trimesh(vertices, indices)

            this.mazeBody = new Body({
              mass: 0,
              material: this.physicsMaterial,
            })
            this.mazeBody.addShape(mesh)
            this.world.addBody(this.mazeBody)
          }
        })

        this.group.add(this.maze)
        this.maze.rotation.order = 'ZXY'
        this.scene.add(this.group)
        this.camera.lookAt(this.maze.position)
        resolve(true)
      })
    )
  }

  addObjects() {
    if (!this.rabbitWorld.rabbit) {
      return
    }

    this.rabbitModel = this.rabbitWorld.rabbit
    this.rabbitWorld.updateRabbitColor(0xff4552)
    this.rabbitModel.rotateY(Math.PI)
    this.rabbitModel.scale.setScalar(0.35)
    // this.rabbitModel.position.y = 1
    this.rabbitModel.castShadow = true
    this.rabbitModel.receiveShadow = true
    this.rabbitModel.position.x = -0.37
    this.rabbitModel.position.z = 0
    this.scene.add(this.rabbitModel)

    if (this.rabbitWorld.carrot) {
      this.carrot = this.rabbitWorld.carrot
      this.rabbitWorld.updateCarrotColor(0xff4552, 0x208d9e, 0x005461)
      this.carrot.scale.set(
        this.carrotScale,
        this.carrotScale,
        this.carrotScale
      )
      this.carrot.position.copy(this.getCarrotPosition())
      this.scene.add(this.carrot)
    }
  }

  addPhysicsObjects() {
    const sphereSize = 0.2
    const sphereShape = new Sphere(sphereSize)
    this.sphereBody = new Body({ mass: 1, material: this.physicsMaterial })
    this.sphereBody.addShape(sphereShape)
    this.sphereBody.position.x = this.rabbitModel.position.x
    this.sphereBody.position.y = this.rabbitModel.position.y
    this.sphereBody.position.z = this.rabbitModel.position.z
    this.world.addBody(this.sphereBody)

    // Top cover, this way the ball wont jump out of play area
    const topPlaneShape = new Box(new Vec3(4, 4, 0.1))
    this.topPlaneBody = new Body({ mass: 0, shape: topPlaneShape })
    this.topPlaneBody.position.y = 0.6
    this.topPlaneBody.quaternion.setFromAxisAngle(
      new Vec3(1, 0, 0),
      -Math.PI / 2
    )
    this.world.addBody(this.topPlaneBody)

    const planeShape = new Plane()
    this.planeBody = new Body({
      mass: 0,
      shape: planeShape,
      material: this.physicsMaterial,
    })
    this.planeBody.position.y = 0.01
    this.planeBody.quaternion.setFromAxisAngle(new Vec3(1, 0, 0), -Math.PI / 2)
    this.world.addBody(this.planeBody)
  }

  getCarrotPosition() {
    const carrot = gsap.utils.random(this.carrotsPositions)

    if (
      this.currentCarrotPosition &&
      this.currentCarrotPosition.x === carrot.position.x
    ) {
      this.getCarrotPosition()
    }

    this.currentCarrotPosition = carrot.position.clone()
    this.currentCarrotPosition.y = this.carrotYPosition

    return this.currentCarrotPosition
  }

  updateCarrotPosition() {
    const position = this.getCarrotPosition()
    this.carrot.visible = false
    this.rabbitWorld.explode(this.carrot.position, this.carrotScale)
    this.carrot.position.copy(position)
  }

  updateRotation() {
    if (!this.isActive) {
      return
    }

    const rotationVector = this.isMobile.any
      ? this.gyroRotationVec
      : this.rotationVec
    const rotationSpeed = this.isMobile.any
      ? this.rotationSpeed * 0.2
      : this.rotationSpeed * 0.05
    const xRotation = MathUtils.clamp(
      rotationVector.x * (this.mazeRotationMultiplier * 0.1),
      -this.maxMazeRotation,
      this.maxMazeRotation
    )
    const zRotation = MathUtils.clamp(
      rotationVector.z * (this.mazeRotationMultiplier * 0.1),
      -this.maxMazeRotation,
      this.maxMazeRotation
    )

    this.world.gravity.z = -xRotation * this.gravityMultiplier
    this.world.gravity.x = zRotation * this.gravityMultiplier

    this.cameraContainer.rotation.z = MathUtils.lerp(
      this.cameraContainer.rotation.z,
      zRotation,
      rotationSpeed
    )
    this.cameraContainer.rotation.x = MathUtils.lerp(
      this.cameraContainer.rotation.x,
      xRotation,
      rotationSpeed
    )

    // Used if meshes are rotated instead of camera
    // this.quatX.setFromAxisAngle(
    //   new Vec3(1, 0, 0),
    //   -Math.PI / 2 + this.maze.rotation.x
    // )
    // this.quatZ.setFromAxisAngle(this.dummyZVector, this.maze.rotation.z)
    // this.quatXMaze.setFromAxisAngle(this.dummyXVector, this.maze.rotation.x)
    // this.quatZMaze.setFromAxisAngle(this.dummyZVector, this.maze.rotation.z)
    // const quaternion = this.quatZ.mult(this.quatX)
    // const quaternionMaze = this.quatZMaze.mult(this.quatXMaze)
    // quaternion.normalize()
    // quaternionMaze.normalize()
    // this.planeBody.quaternion.copy(quaternion)
    // this.topPlaneBody.quaternion.copy(quaternion)
    // this.mazeBody.quaternion.copy(quaternionMaze)

    if (this.sphereBody) {
      if (this.world.gravity.x !== 0) {
        const direction = new Vector3()
        direction.addVectors(this.world.gravity, this.rabbitModel.position)
        direction.y = 0
        this.rabbitModel.lookAt(direction)
      }

      this.rabbitModel.position.copy(this.sphereBody.position)
    }
  }

  onMove(self: Observer) {
    const x = ((self.x || 1) / window.innerWidth) * 2 - 1
    const y = -((self.y || 1) / window.innerHeight) * 2 + 1
    this.rotationVec.set(y, 0, x)
  }

  addEvents() {
    this.observer = Observer.create({
      target: window,
      type: 'pointer',
      onMove: self => this.onMove(self),
    })
  }

  public tick() {
    if (this.maze && this.world) {
      // Debug
      // this.cannonDebugRenderer.update()

      if (!this.initialTiltAmountOnMobile) {
        this.initialTiltAmountOnMobile = this.gyroSensor.alpha
      }

      if (this.isMobile.any) {
        this.gyroRotationVec.set(
          this.gyroSensor.alpha - this.initialTiltAmountOnMobile,
          0,
          this.gyroSensor.gamma
        )
      }

      if (this.currentCarrotPosition) {
        const distance = this.rabbitModel.position.distanceTo(
          this.currentCarrotPosition
        )

        if (distance < this.carrotCollisionRadius) {
          this.updateCarrotPosition()
        }
      }

      if (this.carrot) {
        this.carrot.rotateY(this.carrotRotationSpeed) // normal que ce ne soit pas multiplié par un delta time ?
        this.carrot.rotateZ(this.carrotRotationSpeed)
        this.carrot.rotateX(this.carrotRotationSpeed)
      }

      this.updateRotation()
      // this.world.fixedStep()
      this.world.step((1000 / 30) * 0.001)
    }
  }
}
