import {
  ClampToEdgeWrapping,
  FloatType,
  HalfFloatType,
  LinearFilter,
  Mesh,
  OrthographicCamera,
  PlaneBufferGeometry,
  RGBAFormat,
  Scene,
  WebGLRenderer,
  WebGLRenderTarget,
} from 'three'
import isMobile from 'ismobilejs'

import { SimMaterial } from './SimMaterial'

export class Sim {
  private orthoScene: Scene
  private orthoCamera: OrthographicCamera
  private orthoQuad: Mesh
  private fbos: WebGLRenderTarget[]
  private current = 0
  private input: WebGLRenderTarget
  public output: WebGLRenderTarget

  constructor(
    private renderer: WebGLRenderer,
    width: number,
    height: number,
    private shader: SimMaterial
  ) {
    this.renderer = renderer
    this.shader = shader
    this.orthoScene = new Scene()

    const fbo = new WebGLRenderTarget(width, height, {
      wrapS: ClampToEdgeWrapping,
      wrapT: ClampToEdgeWrapping,
      minFilter: LinearFilter,
      magFilter: LinearFilter,
      format: RGBAFormat,
      type: isMobile().any ? HalfFloatType : FloatType,
      stencilBuffer: false,
      depthBuffer: false,
    })

    fbo.texture.generateMipmaps = false

    this.fbos = [fbo, fbo.clone()]
    ;[this.input] = this.fbos
    ;[this.output] = this.fbos
    this.orthoCamera = new OrthographicCamera(
      width / -2,
      width / 2,
      height / 2,
      height / -2,
      0.00001,
      1000
    )
    this.orthoQuad = new Mesh(
      new PlaneBufferGeometry(width, height),
      this.shader
    )
    this.orthoScene.add(this.orthoQuad)
  }

  render() {
    this.shader.uniforms.inputTexture.value = this.fbos[this.current].texture
    this.input = this.fbos[this.current]
    this.current = 1 - this.current
    this.output = this.fbos[this.current]
    this.renderer.setRenderTarget(this.output)
    this.renderer.render(this.orthoScene, this.orthoCamera)
    this.renderer.setRenderTarget(null)
  }
}
