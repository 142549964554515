<template>
  <div v-for="cat in content" :key="cat.title">
    <h3>{{ cat.title }}</h3>

    <p v-if="cat.description">{{ cat.description }}</p>

    <template v-if="cat.items?.length">
      <div v-for="cookie in cat.items" :key="cookie.title">
        <h4>{{ cookie.title }}</h4>
        <p v-if="cookie.description">{{ cookie.description }}</p>
        <ul v-if="cookie.domain || cookie.lifetime">
          <li v-if="cookie.domain">
            <b>
              {{ chrome.language === 'en' ? 'Domain' : 'Domaine' }} :
              {{ cookie.domain }}</b
            >
          </li>
          <li v-if="cookie.lifetime">
            <b>
              {{ chrome.language === 'en' ? 'Lifetime' : 'Duree' }} :
              {{ cookie.lifetime }}</b
            >
          </li>
        </ul>
        <hr />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue'

import { useChromeStore } from '@/stores/chrome'
const chrome = useChromeStore()

interface CookieItem {
  title: string
  description: string
  domain: string
  lifetime: string
}

interface CookiesList {
  title: string
  description: string
  items: CookieItem[]
}

defineProps({
  content: {
    type: Array as PropType<CookiesList[]>,
    required: true,
  },
})
</script>
