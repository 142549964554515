import { useRoute } from 'vue-router'

/**
 * Add data-input attribute on the container where error should be displayed
 */
export function validateForm(form: HTMLFormElement) {
  const inputs = form.querySelectorAll(':valid')
  inputs.forEach(input => {
    input.closest('[data-input]')?.classList.remove('has-error')
  })

  if (!form.checkValidity()) {
    const inputs = form.querySelectorAll(':invalid')
    const [firstInput] = inputs
    inputs.forEach(input => {
      input.closest('[data-input]')?.classList.add('has-error')
    })

    if (firstInput) {
      ;(firstInput as HTMLInputElement).focus()
      firstInput.parentElement &&
        window.scrollTo(
          0,
          Math.abs(
            firstInput.parentElement.getBoundingClientRect().top +
              window.scrollY -
              100
          )
        )
    }

    return false
  }

  return true
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function preFillField(form: Record<string, any>) {
  const route = useRoute()

  for (const prop in form) {
    if (route.query[prop]) {
      ;(form[prop as keyof typeof form] as string) = route.query[prop] as string
    }
  }
}

export function getTurnstileToken(selector = '.turnstile-widget') {
  return new Promise<{ id: string; token: string }>((resolve, reject) => {
    const params = new URLSearchParams(window.location.search)

    const sitekey =
      params.get('citest') === '1'
        ? import.meta.env.VITE_APP_TURNSTILE_DUMMY
        : import.meta.env.VITE_APP_TURNSTILE_SITE_KEY
    try {
      const id = window.turnstile?.render(selector, {
        sitekey,
        callback: (token: string) => resolve({ id, token }),
      })
    } catch (error) {
      console.error('ERROR WHILE EXECUTING TURNSTILE RENDER', error)
      reject(error)
    }
  })
}

export function removeTurnstile(id: string) {
  try {
    window.turnstile?.remove(id)
  } catch (error) {
    console.error('Turnstile remove error', error)
  }
}
