<template>
  <div ref="rootRef" class="newsletter">
    <!-- <button class="newsletter__trigger" @click="open">
      <span class="newsletter__trigger__label p-medium">
        Subscribe to our newsletter
      </span>
      <SvgSprite
        symbol="icons-paper"
        size="20"
        class="newsletter__trigger__icon"
      />
    </button> -->
    <GBtn
      :background="background"
      :foreground="foreground"
      icon="icons-paper"
      size="tiny"
      @click="open"
    >
      {{ t('newsletter-subscribe') }}
    </GBtn>

    <Teleport to="body">
      <transition name="fade">
        <div v-if="outside.isOpen" class="newsletter__modal">
          <div class="newsletter__modal-outer">
            <div class="newsletter__modal-inner">
              <div class="newsletter__form__header">
                <span class="section-label">
                  {{ t('newsletter-subscribe') }}
                </span>
                <button class="newsletter__form__header__close">
                  <SvgSprite
                    symbol="ui-cross"
                    size="17"
                    @click="outside.isOpen = false"
                  />
                </button>
              </div>
              <form
                v-if="!isSubmitted"
                ref="$form"
                v-outside="outside"
                novalidate
                class="newsletter__form"
                @submit.prevent="onSubmit"
              >
                <span class="h1 newsletter__form__title">
                  {{ t('newsletter-title') }}
                </span>
                <p class="newsletter__form__subtitle">
                  {{ t('newsletter-subtitle') }}
                </p>
                <VInput
                  v-model="form.firstName"
                  :label="t('form-firstname')"
                  name="firstname"
                  class="form-field"
                />
                <VInput
                  v-model="form.lastName"
                  :label="t('form-lastname')"
                  name="lastname"
                  class="form-field"
                />
                <VInput
                  v-model="form.email"
                  :label="t('form-email')"
                  name="email"
                  type="email"
                  class="form-field"
                />

                <Feedback
                  v-if="hasError"
                  :content="feedback"
                  class="form-field"
                />
                <div class="turnstile-widget-newsletter"></div>

                <div class="row end-xxs">
                  <GBtn type="submit" :disabled="isLoading" class="btn--small">
                    {{ t('newsletter-submit') }}
                  </GBtn>
                </div>
              </form>
              <div v-else class="newsletter__done">
                <span class="h1 newsletter__form__title">{{
                  t('newsletter-done-title')
                }}</span>
                <p class="p-big">
                  {{ t('newsletter-done-text') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios'
import { reactive, ref } from 'vue'

import VInput from '@/components/form/Input.vue'
import Feedback from '@/components/ui/Feedback.vue'
import { getApiUrl } from '@/config/app'
import {
  getTurnstileToken,
  removeTurnstile,
  validateForm,
} from '@/modules/form'
import { logger } from '@/utils/logger'
import { push } from '@/utils/tracking'
import { useChromeStore } from '@/stores/chrome'

defineProps({
  background: {
    type: String,
    default: null,
  },
  foreground: {
    type: String,
    default: null,
  },
})

const rootRef = ref<HTMLElement>()
const hasError = ref(false)
const isLoading = ref(false)
const isSubmitted = ref(false)

const feedback = reactive({
  type: 'warning',
  text: '<strong>An error has occured</strong>Please check the mandatory fields and try again.',
})

const { t } = useChromeStore()

const close = () => {
  outside.isOpen = false
  isSubmitted.value = false
  form.value = {
    firstName: '',
    lastName: '',
    email: '',
  }
  document.documentElement.classList.remove('no-scroll')
}

const open = () => {
  outside.isOpen = true
  setTimeout(() => {
    document.documentElement.classList.add('no-scroll')
  }, 100)
}

const outside = reactive({
  isOpen: false,
  action: close,
})

const form = ref({
  firstName: '',
  lastName: '',
  email: '',
})

const $form = ref({} as HTMLFormElement)

const onSubmit = async () => {
  const isValid = validateForm($form.value)

  if (!isValid) {
    return
  }

  isLoading.value = true
  hasError.value = false

  const formData = new FormData($form.value)
  let turnstileWidgetID: string

  try {
    const { id, token } = await getTurnstileToken(
      '.turnstile-widget-newsletter'
    )

    if (token) {
      turnstileWidgetID = id
      formData.append('cf-turnstile-response', token)
    }
  } catch (error) {
    console.error(error)
  }

  axios
    .post(`${getApiUrl()}/newsletter`, formData)
    .catch(res => {
      isLoading.value = false
      hasError.value = true
      logger.error(res)

      return res
    })
    .then(res => {
      if (res.data.status === 200) {
        isLoading.value = false
        isSubmitted.value = true

        const ctx = rootRef.value?.closest('.mega-menu')
        const ctaLocation = ctx ? 'menu' : 'footer'
        const dataLayer = {
          event: 'newsletter_subscription',
          ctaLocation,
          formStatus: 'success',
        }

        push(dataLayer)
      } else {
        isLoading.value = false
        hasError.value = true
        logger.error(res)
      }
    })
    .finally(() => {
      removeTurnstile(turnstileWidgetID)
    })
}
</script>

<style lang="scss" scoped>
.newsletter__modal {
  @include get-all-space;

  position: fixed;
  z-index: $header-z-index + 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsletter__modal-outer {
  padding: 1rem;
  background-color: var(--c-background);
  border: 0.1rem solid var(--c-foreground);
  border-radius: 5px;
  box-shadow: 0 0 30px 0 rgba($c-black, 0.2);
}

.newsletter__modal-inner {
  @include scrollbars($foreground-color: var(--c-foreground));

  overflow: auto;
  width: 90vw;
  max-width: 80rem;
  max-height: 80vh;
  padding: 4rem;

  @include mq(m) {
    padding: 6rem 8rem 8rem;
  }
}

.newsletter__form__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid var(--c-foreground);
  fill: var(--c-foreground);
}

.newsletter__form__header__close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  padding: 0;
  background-color: transparent;
  border: 0;
  fill: var(--c-foreground);

  &:hover {
    cursor: pointer;
  }
}

.newsletter__form__title {
  // prettier-ignore
  @include fluid(font-size,(xxs: 30px, xxl: 70px));

  // prettier-ignore
  @include fluid(line-height,(xxs: 35px, xxl: 75px));

  display: block;
  margin-bottom: 4rem;
}

.newsletter__form__subtitle {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 20px, xxl: 80px));
}
</style>
