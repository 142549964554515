<template>
  <div v-if="content.picture" class="picture-outer" :data-polaroid="isPolaroid">
    <figure
      ref="figure"
      :data-width="content.size"
      :class="{ polaroid: isPolaroid }"
      class="picture"
    >
      <div :class="{ 'is-visible': isVisible }" class="picture__img">
        <div class="picture__placeholder"></div>
        <img
          ref="img"
          :src="content.picture.src"
          :alt="content.picture.alt"
          :srcset="sets"
          :width="content.picture.width"
          :height="content.picture.height"
          :sizes="sizeData"
        />
      </div>

      <figcaption
        v-if="content.picture.description || content.picture.caption"
        :class="{ polaroid__caption: isPolaroid }"
      >
        {{ content.picture.description || content.picture.caption }}
      </figcaption>
      <component
        :is="link.tag"
        v-if="content.link"
        :href="link.url"
        :to="link.path"
        :target="link.target"
        class="picture__cta"
        :title="content.link.label"
      >
        <GCtaRounded
          :icon="content.icon"
          :text="content.link.label"
          :repeat="content.link.repeat"
          :offset="content.link.offset"
          :foreground="foreground"
          :background="background"
          name="read-case"
          size="large"
        />
      </component>
    </figure>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { Observer } from 'gsap/Observer'
import { computed, onBeforeUnmount, onMounted, PropType, ref } from 'vue'

import { useAppear } from '@/utils/aware'
import { getValue } from '@/modules/colors'
import { Picture } from '@/types'

interface Content {
  icon?: string
  picture: Picture
  noStretch: boolean
  size: string
  link?: {
    label: string
    url: string
    repeat?: number
    offset?: number
  }
}

const props = defineProps({
  content: {
    type: Object as PropType<Content>,
    required: true,
  },
  isPolaroid: Boolean,
  sizes: {
    type: String,
    default: '100vw',
  },
  deferredLoading: {
    type: Number,
    default: 0,
  },
})

const foreground = ref('')
const background = ref('')

const sets = computed(() =>
  Object.keys(props.content?.picture?.sets).reduce(
    (prev, current) =>
      // eslint-disable-next-line prefer-template
      `${prev ? prev + ' ' : ''}${
        props.content.picture?.sets[current]
      } ${current}w,`,
    ''
  )
)

const figure = ref<HTMLElement | null>(null)
const img = ref<HTMLImageElement | null>(null)
const isVisible = ref(false)
const sizeData = ref('100px')
const imageWidth = ref('full')
const link = computed(() => {
  const link = {} as Record<string, string | undefined>
  const isExternal = /^[http|https|/]/.test(props.content.link?.url || '')

  if (isExternal) {
    link.tag = 'a'
    link.url = props.content.link?.url
    link.target = '_blank'
  } else {
    link.tag = 'router-link'
    link.path = props.content.link?.url
  }

  return link
})

const onAppear = ({ isInViewport }: { isInViewport: boolean }) => {
  img.value?.addEventListener('load', onLoaded)

  if (isInViewport) {
    isVisible.value = true
    sizeData.value = props.sizes
    imageWidth.value = props.content.size
  }
}

const onLoaded = () => {
  figure.value?.classList.add('is-loaded')
}

let dragIcon: HTMLElement | null
let hoverObserver: Observer
let mutationObserver: MutationObserver
let isHovering = false

const onMove = (o: Observer) => {
  if (!isHovering) {
    return
  }

  gsap.to(dragIcon, { duration: 0.1, x: o.x, y: o.y })
  gsap.to(dragIcon, {
    delay: 0.05,
    duration: 0.25,
    scale: 1,
    ease: 'power2.out',
  })
}

const hideIcon = () => {
  isHovering = false
  gsap.killTweensOf(dragIcon)
  gsap.to(dragIcon, { duration: 0.2, rotate: 0, scale: 0 })
}

const initHover = () => {
  const isDraggable =
    figure.value?.parentElement?.classList.contains('is-draggable')

  if (!isDraggable || hoverObserver) {
    return
  }

  hoverObserver = Observer.create({
    target: figure.value,
    type: 'pointer, wheel',
    onMove,
    onHover: () => {
      isHovering = true
      gsap.to(dragIcon, {
        duration: 1,
        rotate: 360,
        ease: 'power2.out',
      })
    },
    onHoverEnd: hideIcon,
    onWheel: hideIcon,
    onPress: () => {
      dragIcon?.classList.add('is-pressed')
    },
    onRelease: () => {
      dragIcon?.classList.remove('is-pressed')
    },
  })
}

const initObservers = () => {
  if (!figure.value?.parentElement || hoverObserver) {
    return
  }

  initHover()

  dragIcon = document.querySelector('[data-drag-icon]')
  mutationObserver = new MutationObserver(() => {
    initHover()
  })

  mutationObserver.observe(figure.value.parentElement, {
    attributes: true,
    childList: false,
    subtree: false,
  })
}

useAppear(figure, onAppear, { once: true })

onMounted(() => {
  initObservers()
  foreground.value = getValue('foreground', document.documentElement)
  background.value = getValue('background', document.documentElement)

  if (props.deferredLoading) {
    img.value?.addEventListener('load', onLoaded)

    setTimeout(() => {
      sizeData.value = props.sizes
    }, props.deferredLoading)
  }
})

onBeforeUnmount(() => {
  hoverObserver && hoverObserver.kill()
  mutationObserver && mutationObserver.disconnect()
  img.value?.removeEventListener('load', onLoaded)
  hideIcon()
})
</script>

<style lang="scss">
/* stylelint-disable declaration-no-important */
.polaroid {
  margin: 0;
  padding: 1rem !important;
  background: var(--c-background);
  border: 1px solid rgba(var(--c-foreground-rgb), 0.5);
  box-shadow: 10px 10px 0 rgba($c-black, 0.2);
}

.polaroid__caption {
  margin-top: 1rem;
  color: var(--c-foreground);
  font-size: 1.2rem;
  line-height: 1.3;
}
</style>

<style lang="scss" scoped>
.picture-outer {
  &[data-polaroid] {
    @extend %picture-perspective;

    pointer-events: none;
  }

  &.is-draggable {
    cursor: none !important;
  }
}

.picture {
  position: relative;
  padding: 0;
  pointer-events: auto;

  &[data-width='original'] {
    width: fit-content;
    margin: auto;
    text-align: center;
  }

  &[data-width='grid'] {
    margin: auto;

    @include mq(l) {
      width: col(8);
    }
  }

  @include mq($until: l) {
    max-width: 100%;

    &:not([data-width='original']) {
      width: 100% !important;
    }
  }
}

.picture__cta {
  @include get-all-space;

  display: flex;
  justify-content: center;
  align-items: center;
}

.picture__img {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;

  img {
    opacity: 0;
    transition: opacity 0.25s;
  }

  .is-loaded & {
    img {
      opacity: 1;
    }
  }
}

.picture__placeholder {
  @include get-all-space;

  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 2px solid rgba(var(--c-foreground-rgb), 0.2);
  pointer-events: none;
  transition: opacity 0.25s;

  &::after {
    @include get-all-space;

    content: '';
    z-index: 10;
    background: var(--c-background);
    filter: brightness(0.9);
  }

  svg {
    fill: var(--c-foreground);
  }

  .is-loaded & {
    opacity: 0;
  }
}
</style>
