/* eslint-disable lines-around-comment, id-length */

import {
  MaybeElementRef,
  // ResizeObserverCallback,
  ResizeObserverEntry,
  UseResizeObserverOptions,
  useResizeObserver,
  UseResizeObserverReturn,
} from '@vueuse/core'
import { tryOnScopeDispose } from '@vueuse/shared'
import { watch } from 'vue-demi'

import { useViewport } from './useViewport'

export type ResizeCallback = (data: {
  width: number
  height: number
  ratio: number
}) => void

export interface ResizeOptions extends UseResizeObserverOptions {
  /**
   * TODO: Execute the callback only once.
   *
   * @default false
   */
  once?: boolean

  /**
   * TODO: Throttle value.
   */
  throttle?: number

  /**
   * TODO: Debounce value.
   */
  debounce?: number
}

// NB: ResizeObserver is `immediate` by default

export interface UseResizeReturn {
  stop: () => void
  isSupported: boolean | undefined
}

const viewport = useViewport()

/**
 * Overload 1: No target, use window.resize
 *
 * @param labels
 * @param options
 */
export function useResize(
  callback: ResizeCallback,
  options?: ResizeOptions
): UseResizeReturn
/**
 * Overload 2: With target element, use ResizeObserver
 *
 * @param target
 * @param labels
 * @param options
 */
export function useResize(
  target: MaybeElementRef,
  callback: ResizeCallback,
  options?: ResizeOptions
): UseResizeReturn
export function useResize(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any[]
): UseResizeReturn | UseResizeObserverReturn {
  let target: MaybeElementRef
  let callback: ResizeCallback
  let options: ResizeOptions

  const defaultOptions: ResizeOptions = { once: false }

  if (typeof args[0] === 'function') {
    ;[callback, options = defaultOptions] = args
  } else {
    ;[target, callback, options = defaultOptions] = args
  }

  if (target) {
    // eslint-disable-next-line no-inner-declarations
    function wrappedCallback(
      entries: ReadonlyArray<ResizeObserverEntry>
      // observer: ResizeObserver
    ) {
      entries.forEach(entry => {
        // Each entry element corresponds to a set of properties
        // for one of the target elements:
        const {
          borderBoxSize,
          // contentBoxSize,
          // devicePixelContentBoxSize,
          contentRect,
          // target,
        } = entry

        let width: number
        let height: number
        // let widthContent: number
        // let heightContent: number

        if (borderBoxSize && borderBoxSize.length > 0) {
          width = borderBoxSize[0].inlineSize
          height = borderBoxSize[0].blockSize
        } else {
          ;({ width, height } = contentRect)
        }

        const ratio = width / height

        // if (contentBoxSize && contentBoxSize.length > 0) {
        //   widthContent = contentBoxSize[0].inlineSize
        //   heightContent = contentBoxSize[0].blockSize
        // } else {
        //   ;({ widthContent, heightContent } = contentRect)
        // }

        callback({ width, height, ratio })
      })
    }

    return useResizeObserver(
      target,
      wrappedCallback,
      options as ResizeObserverOptions
    )
  }

  const { width, height, ratio } = viewport
  const stopWatch = watch(
    () => ({
      width: width.value,
      height: height.value,
      ratio: ratio.value,
    }),
    callback
  )

  const stop = () => {
    stopWatch()
  }

  tryOnScopeDispose(stop)

  callback({
    width: width.value,
    height: height.value,
    ratio: ratio.value,
  })

  return {
    stop,
    isSupported: undefined,
  }
}
