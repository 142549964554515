<template>
  <div class="mega-menu" :class="{ 'is-active': isActive }">
    <transition :css="false" mode="out-in" @enter="onEnter" @leave="onLeave">
      <div v-show="isActive" ref="navRef" class="mega-menu-inner">
        <div class="wrapper">
          <nav class="mega-menu__content row">
            <div class="mega-menu__block col-xxs-12 col-s-6">
              <ol class="mega-menu__links">
                <li v-for="nav in chrome.main" :key="nav.label" ref="linksRef">
                  <GAction
                    :url="nav.url"
                    :title="nav.label"
                    :target="nav.target"
                    class="mega-menu__links__item h2"
                    @click="onLinkClick"
                  >
                    {{ nav.label }}
                  </GAction>
                </li>
              </ol>

              <ol
                v-if="chrome.secondary !== null && chrome.secondary.length"
                class="mega-menu__links--secondary"
              >
                <li
                  v-for="nav in chrome.secondary"
                  :key="nav.label"
                  ref="linksRef"
                >
                  <GAction
                    :url="nav.url"
                    :title="nav.label"
                    class="mega-menu__links__item--secondary p-medium"
                    @click="onLinkClick"
                  >
                    {{ nav.label }}
                  </GAction>
                </li>
              </ol>
            </div>

            <div class="mega-menu__block col-xxs-12 col-s-6 mega-menu__cta">
              <UiCta
                ref="ctaRef"
                :content="{
                  url:
                    chrome.language === 'en'
                      ? '/en/contact/new-project/'
                      : '/fr/contact/nouveau-projet/',
                  ...ctaData,
                }"
                background="#323336"
                foreground="#c0ae7f"
                class="mega-menu__cta__link"
                @click="onLinkClick"
              />
            </div>
          </nav>

          <div v-for="i in 1" :key="i" class="mega-menu__footer">
            <Socials
              ref="footersRef"
              :links="chrome.socials"
              background="#323336"
              foreground="#c0ae7f"
              class="mega-menu__socials"
            />
            <Newsletter
              ref="footersRef"
              background="#323336"
              foreground="#c0ae7f"
              @click="onLinkClick"
            />
            <nav ref="footersRef" class="mega-menu__legals mega-menu__content">
              <ol v-if="chrome.legals" class="mega-menu__links--secondary">
                <li
                  v-for="nav in chrome.legals"
                  :key="nav.label"
                  ref="linksRef"
                >
                  <GAction
                    :url="nav.url"
                    :target="nav.target"
                    class="mega-menu__links__item--secondary mega-menu__legals__link"
                    @click="onLinkClick"
                  >
                    {{ nav.label }}
                  </GAction>
                </li>
              </ol>
            </nav>
            <router-link
              ref="footersRef"
              to="/epic-fonds-feder/"
              @click="onLinkClick"
            >
              <img
                src="@/assets/images/gov.png"
                alt="UE Wallonie logo"
                class="mega-menu__logo"
              />
              <span class="h-visually-hidden">Fonds FEDER</span>
            </router-link>
          </div>
        </div>
      </div>
    </transition>

    <button aria-label="Open Menu" class="mega-menu__trigger" @click="onClick">
      <span></span>
      <span></span>
      <span></span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { CustomEase } from 'gsap/CustomEase'
import { ref, ComponentPublicInstance } from 'vue'

import { useChromeStore } from '@/stores/chrome'
import { useNavigationStore } from '@/stores/navigation'
import { Cta, RefsArray } from '@/types'
import { hasMotion } from '@/core/prefers'
import { getColors } from '@/modules/colors'

import Socials from '@/components/ui/Socials.vue'
import UiCta from '@/components/ui/Cta.vue'
import Newsletter from './Newsletter.vue'

gsap.registerPlugin(CustomEase)

let isAnimating = false

const chrome = useChromeStore()
const navigation = useNavigationStore()

const isActive = ref(false)
const linksRef = ref<HTMLElement[]>([])
const ctaRef = ref<InstanceType<typeof UiCta> | null>(null)
const footersRef = ref<RefsArray>([])
const menuColor = '#323336'

const ctaData = ref<Cta>({
  headline: chrome.language === 'fr' ? 'Commencez un' : 'Start a',
  title: chrome.language === 'fr' ? 'nouveau \\ projet' : 'new \\ project',
  baseline: chrome.language === 'fr' ? 'avec nous' : 'with us',
})

const onLinkClick = () => {
  isActive.value = false
  navigation.trigger = 'megamenu'
  document.documentElement.classList.remove('no-scroll')
}

const onClick = () => {
  if (isAnimating) {
    return
  }

  document.documentElement.classList.toggle('no-scroll')

  isAnimating = true
  isActive.value = !isActive.value
}

const onEnter = (el: Element, done: () => void) => {
  document.documentElement.style.setProperty('--c-menu-background', menuColor)

  if (ctaRef.value) {
    ctaRef.value.updateBCR()

    const els = [
      ...linksRef.value,
      ctaRef.value.$el,
      footersRef.value.map(el => (el as ComponentPublicInstance).$el || el),
    ]

    gsap.killTweensOf([el, ...els])
    const tl = gsap.timeline()

    if (!hasMotion.value) {
      tl.set(el, { clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' })
      done()
      isAnimating = false

      return
    }

    tl.to(el, {
      duration: 1,
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
      ease: CustomEase.create('custom', 'M0,0 C0,1 0.102,1 1,1 '),
      onComplete: () => {
        done()
        isAnimating = false
      },
    }).from(
      els,
      {
        y: 30,
        stagger: 0.03,
        opacity: 0,
        ease: 'power2.out',
      },
      0
    )
  }
}

const onLeave = (el: Element, done: () => void) => {
  if (ctaRef.value) {
    const els = [
      ...linksRef.value,
      ctaRef.value.$el,
      footersRef.value.map(el => (el as ComponentPublicInstance).$el || el),
    ]

    gsap.killTweensOf([el, ...els])
    const tl = gsap.timeline({
      onComplete: () => {
        gsap.set(els, { clearProps: 'all' })
        const p = { progress: 0 }
        gsap.to(p, {
          duration: 0.3,
          ease: 'linear',
          progress: 1,
          onUpdate: () => {
            const color = gsap.utils.interpolate(
              menuColor,
              getColors().background,
              p.progress
            )
            document.documentElement.style.setProperty(
              '--c-menu-background',
              color
            )
          },
          onComplete: () => {
            document.documentElement.style.setProperty(
              '--c-menu-background',
              ''
            )
          },
        })
      },
    })

    if (!hasMotion.value) {
      tl.set(el, { clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)' })
      done()
      isAnimating = false

      return
    }

    tl.to(els, {
      y: -30,
      stagger: 0.03,
      opacity: 0,
      ease: 'power2.out',
    })
    tl.to(
      el,
      {
        duration: 1,
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
        ease: CustomEase.create('custom', 'M0,0 C0,1 0.102,1 1,1 '),
        onComplete: () => {
          done()
          isAnimating = false
        },
      },
      0.3
    )
  }
}
</script>

<style lang="scss" scoped>
/* stylelint-disable declaration-no-important */
$bp: l;

.mega-menu {
  pointer-events: auto;
}

.mega-menu-inner {
  // prettier-ignore
  @include fluid(padding-top,(xxs: 60px, s: 95px));

  // prettier-ignore
  @include fluid( padding-bottom, ( xxs: 60px, s: 95px));
  @include scrollbars($foreground-color: $ecru);
  @include get-all-space;

  position: fixed;
  z-index: 1;
  display: block;
  overflow: auto;
  color: $ecru;
  background-color: $jet;
  opacity: 1;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);

  .no-scroll & {
    padding-right: var(--scrollbar-width);
  }

  .no-scroll body[data-device-type='mobile'] &,
  .no-scroll body[data-device-type='tablet'] & {
    padding-right: 0;
  }
}

.mega-menu__trigger {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 3.5rem;
  // margin-right: -1rem;
  padding: 1rem;
  background-color: transparent;
  cursor: pointer;
  border: 0;

  span {
    width: 100%;
    height: 2px;
    margin: 2px 0;
    background: var(--c-foreground);
    transition: 0.25s;
  }

  .is-active & {
    margin-right: 0 !important;
    transform: none !important;

    &::after {
      display: none;
    }

    span {
      background: $ecru !important;
    }

    span:nth-of-type(1) {
      transform: rotate(45deg) translateY(4.5px);
    }

    span:nth-of-type(2) {
      transform: rotate(-45deg) scaleX(1.5);
    }

    span:nth-of-type(3) {
      transform: rotate(45deg) translateY(-4px);
    }
  }
}

.mega-menu__content {
  border: solid rgba($ecru, 0.5);
  border-width: 1px 0;

  a {
    color: $ecru !important;
    transition: filter 0.15s;

    &:hover {
      filter: brightness(1.2);
    }
  }
}

.mega-menu__block {
  // prettier-ignore
  @include fluid(padding-top,(xxs: 40px, xxl: 100px));

  // prettier-ignore
  @include fluid( padding-bottom, ( xxs: 40px, xxl: 100px));

  border-bottom: 1px solid rgba($ecru, 0.5);

  @include mq(s) {
    border-bottom: 0;
  }
}

.mega-menu__links,
[class*='mega-menu__links--'] {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &[class*='--secondary'] {
    // prettier-ignore
    @include fluid(padding-top,(xxs: 40px, xxl: 60px));

    display: flex;
    justify-content: center;

    li {
      &:not(:last-child) {
        margin-right: 3rem;
        margin-bottom: 0;
      }
    }
  }

  @include mq(s) {
    border-right: 1px solid rgba($ecru, 0.5);
  }
}

.mega-menu__links__item,
[class*='mega-menu__links__item--'] {
  display: block;
  text-align: center;
  text-transform: none;
}

.mega-menu__cta {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mega-menu__cta__link {
  margin: 0;

  :deep(.cta__word) {
    // prettier-ignore
    @include fluid(font-size,(xxs: 40px, s: 40px, xxl: 80px));

    // prettier-ignore
    @include fluid(line-height,(xxs: 40px, s: 40px, xxl: 80px));

    background-image: linear-gradient($ecru, $ecru);
  }
}

.mega-menu__footer {
  // prettier-ignore
  @include fluid(margin-top,(xxs: 40px, xxl: 80px));

  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq($bp) {
    flex-direction: row;
    margin-bottom: 0;
  }
}

.mega-menu__socials {
  @include mq($until: $bp) {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 4rem;

    :deep(.socials__label) {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 2rem;
      text-align: center;
    }
  }

  @include mq($bp) {
    margin-right: 1.5rem;
  }
}

.mega-menu__legals,
.mega-menu__newsletter {
  margin-bottom: 4rem;

  @include mq($bp) {
    margin-bottom: 0;
  }
}

.mega-menu__legals {
  margin-top: 1rem;
  border: 0;

  [class*='mega-menu__links--'][class*='--secondary'] {
    padding-top: 0;
    border: 0;
  }

  @include mq($bp) {
    // prettier-ignore
    @include fluid(margin-right,(xxs: 20px, xxxl: 75px));

    margin-top: 0;
    margin-right: 7.5rem;
    margin-left: auto;
  }
}

.mega-menu__legals__link {
  // prettier-ignore
  @include fluid(font-size,(xxs: 12px, xxl: 15px));
}

.mega-menu__logo {
  max-width: 18rem;
}
</style>
