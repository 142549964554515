<template>
  <div ref="headerRef" class="header" :class="{ 'has-scrolled': hasScrolled }">
    <div class="header-outer">
      <div class="header-inner">
        <RouterLink
          :to="`/${chrome.language}/`"
          class="header__logo"
          title="Epic"
        >
          <SvgSprite symbol="epic-logo" size="35" />
        </RouterLink>
        <Transition name="fade" mode="out-in">
          <ChromeTitle
            :key="resourceStore.content.title"
            :title="
              resourceStore.template === 'article'
                ? 'Journal'
                : resourceStore.content.title || ''
            "
            class="header__title"
          />
        </Transition>
        <div class="header__menu-outer">
          <div
            class="language__switcher"
            :class="{ actif: languageOpen }"
            @focus="languageOpen = true"
            @mouseenter="languageOpen = true"
            @focusout="languageOpen = false"
            @mouseleave="languageOpen = false"
          >
            <Lang />
          </div>
          <div
            v-if="resourceStore.template === 'article'"
            class="header__menu__toggle"
          >
            <Transition name="fade" mode="out-in" :duration="300">
              <SvgSprite
                v-if="lightMode"
                symbol="icons-sun"
                size="24"
                class="header__menu__toggle__icon"
              />
              <SvgSprite
                v-else
                symbol="icons-moon"
                size="24"
                class="header__menu__toggle__icon"
              />
            </Transition>
            <Toggle v-model="lightMode" />
          </div>
          <ChromeMegaMenu class="header__menu" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import Cookies from 'js-cookie'

import Toggle from '@/components/ui/Toggle.vue'
import ChromeMegaMenu from '@/components/chrome/MegaMenu.vue'
import ChromeTitle from '@/components/chrome/Title.vue'
import Lang from '@/components/chrome/Lang.vue'

import { useUiStore } from '@/stores/ui'
import { useResourceStore } from '@/stores/resource'
import { useResize, useScroll } from '@/utils/aware'
import { setBackground, setForeground } from '@/modules/colors'

import { useChromeStore } from '@/stores/chrome'
const chrome = useChromeStore()

const hasScrolled = ref(false)
const scroll = useScroll({
  callback: () => {
    hasScrolled.value = scroll.y.value > 0
  },
})

const uiStore = useUiStore()
const resourceStore = useResourceStore()
const headerRef = ref<HTMLDivElement>()

const languageOpen = ref(false)

const lightMode = ref(Cookies.get('light_mode') === 'on')
const onColorSwitch = (mode: boolean) => {
  if (mode) {
    Cookies.set('light_mode', 'on')
    setForeground('#323336')
    setBackground('#fef6e5')
  } else {
    Cookies.set('light_mode', 'off')
    setBackground('#323336')
    setForeground('#fef6e5')
  }
}

watch(lightMode, onColorSwitch)

onMounted(() => {
  if (resourceStore.template === 'article') {
    onColorSwitch(lightMode.value)
  }

  if (!headerRef.value) {
    return
  }

  hasScrolled.value = window.scrollY > 0

  useResize(
    headerRef.value,
    () => {
      uiStore.headerHeight = headerRef.value?.offsetHeight || 0
      document.documentElement.style.setProperty(
        '--header-height',
        `${uiStore.headerHeight}px`
      )
    },
    {}
  )
})
</script>

<style lang="scss" scoped>
.header,
[class*='header--'] {
  position: sticky;
  z-index: $header-z-index;
  top: 0;
  right: 0;
  left: 0;
  pointer-events: none;

  // Prevent layout shift in fixed header when scrollbar disapear
  .no-scroll & {
    right: var(--scrollbar-width);
  }

  :deep(.mega-menu__trigger),
  .header__logo {
    transition: transform 0.25s;
  }

  &.has-scrolled {
    .header__logo,
    :deep(.mega-menu__trigger) {
      position: relative;

      &::after {
        @include center-xy;

        content: '';
        z-index: -1;
        width: 6rem;
        height: 6rem;
        background-color: var(--c-background);
        border-radius: 50%;
      }
    }
  }

  @include mq($until: s) {
    background-color: var(--c-background);
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.header__logo {
  line-height: 1;
  fill: var(--c-foreground);
  pointer-events: auto;
}

/* stylelint-disable-next-line no-descending-specificity */
.header__logo,
.header__menu-outer {
  pointer-events: all;
}

.header__menu__toggle,
.header__menu-outer {
  display: flex;
  align-items: center;
}

.header__menu__toggle {
  margin-right: 13.8rem;
  opacity: 1;
  transition: opacity 0.15s;

  .has-scrolled & {
    opacity: 0;
    pointer-events: none;
  }

  @include mq($until: s) {
    margin-right: 11.8rem;
  }
}

.header__menu__toggle__icon {
  margin-right: 1rem;
  fill: var(--c-foreground);
}

.header-outer {
  // prettier-ignore
  @include fluid(margin-right,(xxs: 20px, xxl: 120px));

  // prettier-ignore
  @include fluid(margin-left,(xxs: 20px, xxl: 120px));

  transition: margin-left 0.5s $ease-out-quad, margin-right 0.5s $ease-out-quad;

  @include mq(s) {
    .has-scrolled & {
      margin-right: 2rem;
      margin-left: 2rem;
    }
  }
}

.header-inner {
  // prettier-ignore
  @include fluid(padding-top,(xxs: 20px, xxl: 32px));

  // prettier-ignore
  @include fluid(padding-bottom,(xxs: 20px, xxl: 32px));

  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  transition: max-width 0.5s $ease-out-quad;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid var(--c-foreground);
    transition: transform 0.5s $ease-out-expo;
  }

  .has-scrolled & {
    max-width: 98%;
    transition: max-width 0.5s $ease-out-quad;

    &::after {
      transform: scale(0);
    }
  }
}

.header__title {
  @include center-xy;

  opacity: 1;
  transition: opacity 0.35s $ease-in-out;

  .has-scrolled & {
    opacity: 0;
  }

  @include mq($until: s) {
    display: none;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease 0.15s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.header__lang {
  &.actif {
    svg {
      transform: rotate(180deg);
    }

    .switcher__list {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.language__switcher {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);

  &.actif {
    svg {
      transform: rotate(180deg);
    }

    .switcher__list {
      transform: translateY(0);
      opacity: 1;
      pointer-events: all;
    }
  }
}

.switcher__list {
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-top: 6px;
  list-style: none;
  transform: translateY(-30px);
  opacity: 0;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}

.button__lang {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4px 12px;
  background-color: var(--c-background);
  border: 1px solid var(--c-foreground);
  border-radius: 20px;
  gap: 6px;
  cursor: pointer;

  span {
    @extend %fw-semi;

    color: var(--c-foreground);
    text-transform: uppercase;
  }

  svg {
    fill: var(--c-foreground);
  }
}
</style>
