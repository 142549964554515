import {
  Color,
  LinearFilter,
  MathUtils,
  Matrix4,
  Mesh,
  PerspectiveCamera,
  Plane,
  ShaderMaterial,
  UniformsUtils,
  UniformsLib,
  Vector2,
  Vector3,
  Vector4,
  WebGLRenderTarget,
  ShapeGeometry,
  Camera,
  Scene,
  WebGLRenderer,
  RGBAFormat,
} from 'three'

import vIceFloorShader from '../shaders/iceFloor_vert.glsl?raw'
import fIceFloorShader from '../shaders/iceFloor_frag.glsl?raw'

class Reflector extends Mesh {
  private getRenderTarget: () => WebGLRenderTarget
  public static isReflector = true
  public static ReflectorShader = {
    uniforms: UniformsUtils.merge([
      UniformsLib.common,
      // UniformsLib.shadowmap,
      UniformsLib.lights,
      {
        color: { value: null },
        tDiffuse: { value: null },
        textureMatrix: { value: null },
        tScratches: { value: null },
        heroPos: { value: null },
        carrotPos: { value: null },
        heroHeight: { value: null },
        carrotHeight: { value: null },
        noiseMap: { value: null },
        time: { value: 0 },
      },
    ]),

    vertexShader: vIceFloorShader,
    fragmentShader: fIceFloorShader,
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(geometry: ShapeGeometry, options: Record<string, any> = {}) {
    super(geometry)

    this.type = 'Reflector'

    const color = options.color ? new Color(options.color) : new Color(0x7f7f7f)
    const textureWidth = options.textureWidth || 512
    const textureHeight = options.textureHeight || 512
    const clipBias = options.clipBias || 0
    const shader = options.shader || Reflector.ReflectorShader
    const noiseMap = options.noiseMap || null

    const reflectorPlane = new Plane()
    const normal = new Vector3()
    const reflectorWorldPosition = new Vector3()
    const cameraWorldPosition = new Vector3()
    const rotationMatrix = new Matrix4()
    const lookAtPosition = new Vector3(0, 0, -1)
    const clipPlane = new Vector4()

    const view = new Vector3()
    const target = new Vector3()
    const q = new Vector4()

    const textureMatrix = new Matrix4()
    const virtualCamera = new PerspectiveCamera()

    const parameters = {
      minFilter: LinearFilter,
      magFilter: LinearFilter,
      format: RGBAFormat,
    }

    const renderTarget = new WebGLRenderTarget(
      textureWidth,
      textureHeight,
      parameters
    )

    if (
      !MathUtils.isPowerOfTwo(textureWidth) ||
      !MathUtils.isPowerOfTwo(textureHeight)
    ) {
      renderTarget.texture.generateMipmaps = false
    }

    const material = new ShaderMaterial({
      uniforms: UniformsUtils.clone(shader.uniforms),
      fragmentShader: shader.fragmentShader,
      vertexShader: shader.vertexShader,
    })

    material.uniforms.tDiffuse.value = renderTarget.texture
    material.uniforms.color.value = color
    material.uniforms.textureMatrix.value = textureMatrix
    material.uniforms.tScratches.value = null
    material.uniforms.heroPos.value = new Vector2(0.5, 0.5)
    material.uniforms.carrotPos.value = new Vector2(0.5, 0.5)
    material.uniforms.heroHeight.value = 0
    material.uniforms.carrotHeight.value = 0
    material.uniforms.noiseMap.value = noiseMap
    material.uniforms.time.value = 0

    material.lights = true
    this.material = material

    this.onBeforeRender = (
      renderer: WebGLRenderer,
      scene: Scene,
      camera: Camera
    ) => {
      reflectorWorldPosition.setFromMatrixPosition(this.matrixWorld)
      cameraWorldPosition.setFromMatrixPosition(camera.matrixWorld)

      rotationMatrix.extractRotation(this.matrixWorld)

      normal.set(0, 0, 1)
      normal.applyMatrix4(rotationMatrix)

      view.subVectors(reflectorWorldPosition, cameraWorldPosition)

      // Avoid rendering when reflector is facing away

      if (view.dot(normal) > 0) {
        return
      }

      view.reflect(normal).negate()
      view.add(reflectorWorldPosition)

      rotationMatrix.extractRotation(camera.matrixWorld)

      lookAtPosition.set(0, 0, -1)
      lookAtPosition.applyMatrix4(rotationMatrix)
      lookAtPosition.add(cameraWorldPosition)

      target.subVectors(reflectorWorldPosition, lookAtPosition)
      target.reflect(normal).negate()
      target.add(reflectorWorldPosition)

      virtualCamera.position.copy(view)
      virtualCamera.up.set(0, 1, 0)
      virtualCamera.up.applyMatrix4(rotationMatrix)
      virtualCamera.up.reflect(normal)
      virtualCamera.lookAt(target)

      virtualCamera.far = camera.far // Used in WebGLBackground

      virtualCamera.updateMatrixWorld()
      virtualCamera.projectionMatrix.copy(camera.projectionMatrix)

      // Update the texture matrix
      textureMatrix.set(
        0.5,
        0.0,
        0.0,
        0.5,
        0.0,
        0.5,
        0.0,
        0.5,
        0.0,
        0.0,
        0.5,
        0.5,
        0.0,
        0.0,
        0.0,
        1.0
      )
      textureMatrix.multiply(virtualCamera.projectionMatrix)
      textureMatrix.multiply(virtualCamera.matrixWorldInverse)
      textureMatrix.multiply(this.matrixWorld)

      // eslint-disable-next-line max-len
      // Now update projection matrix with new clip plane, implementing code from: http://www.terathon.com/code/oblique.html
      // Paper explaining this technique: http://www.terathon.com/lengyel/Lengyel-Oblique.pdf
      reflectorPlane.setFromNormalAndCoplanarPoint(
        normal,
        reflectorWorldPosition
      )
      reflectorPlane.applyMatrix4(virtualCamera.matrixWorldInverse)

      clipPlane.set(
        reflectorPlane.normal.x,
        reflectorPlane.normal.y,
        reflectorPlane.normal.z,
        reflectorPlane.constant
      )

      const { projectionMatrix } = virtualCamera

      q.x =
        (Math.sign(clipPlane.x) + projectionMatrix.elements[8]) /
        projectionMatrix.elements[0]
      q.y =
        (Math.sign(clipPlane.y) + projectionMatrix.elements[9]) /
        projectionMatrix.elements[5]
      q.z = -1.0
      q.w =
        (1.0 + projectionMatrix.elements[10]) / projectionMatrix.elements[14]

      // Calculate the scaled plane vector
      clipPlane.multiplyScalar(2.0 / clipPlane.dot(q))

      // Replacing the third row of the projection matrix
      projectionMatrix.elements[2] = clipPlane.x
      projectionMatrix.elements[6] = clipPlane.y
      projectionMatrix.elements[10] = clipPlane.z + 1.0 - clipBias
      projectionMatrix.elements[14] = clipPlane.w

      // Render

      renderTarget.texture.encoding = renderer.outputEncoding

      this.visible = false

      const currentRenderTarget = renderer.getRenderTarget()

      const currentXrEnabled = renderer.xr.enabled
      const currentShadowAutoUpdate = renderer.shadowMap.autoUpdate

      renderer.xr.enabled = false // Avoid camera modification
      renderer.shadowMap.autoUpdate = false // Avoid re-computing shadows

      renderer.setRenderTarget(renderTarget)

      // Make sure the depth buffer is writable so it can be properly cleared, see #18897
      renderer.state.buffers.depth.setMask(true)

      if (renderer.autoClear === false) {
        renderer.clear()
      }

      renderer.render(scene, virtualCamera)

      renderer.xr.enabled = currentXrEnabled
      renderer.shadowMap.autoUpdate = currentShadowAutoUpdate

      renderer.setRenderTarget(currentRenderTarget)

      // Restore viewport
      // const { viewport } = camera

      // if (viewport !== undefined) {
      //   renderer.state.viewport(viewport)
      // }

      this.visible = true
    }

    this.getRenderTarget = () => renderTarget
  }
}

export { Reflector }
