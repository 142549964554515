import { Component } from 'vue'
import { RouteRecordRaw } from 'vue-router'

import { langAvailable, langDefault } from '@/config/languages'
import Root from '@/views/Root.vue'

// Route segments
export const segments = {
  // foo: ['foo-fr', 'foo-nl'],
  workArchive: ['work', 'projets'],
  services: ['services', 'nos-services'],
  careers: ['careers', 'jobs'],
  thankyou: ['thank-you', 'merci'],
  job: ['job', 'job'],
  work: ['work', 'projet'],
} as Record<string, string | string[]>

// Format translated route segments
// formatSegment('foo') => ':segment(foo-fr|foo-nl)'
// formatSegment('bar', 'qux) => ':qux(bar-bar)'
const formatSegment = (segmentKey: string, paramName = 'segment'): string =>
  Array.isArray(segments[segmentKey])
    ? `:${paramName}(${(segments[segmentKey] as string[]).join('|')})`
    : `:${paramName}(${segments[segmentKey] as string})`

let routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/views/Homepage.vue'),
    name: 'homepage',
    // meta: {
    //   static: true,                  // No automatic call to API (WP)
    //   endpoint: 'posts',             // Specific endpoint
    //   api: 'http://some-other-api',  // Use different API address
    //   key: 'id',                     // Avoid component re-rendering
    //   root: true,                    // Root route even with languages
    // },
  },
  {
    path: `/${formatSegment('workArchive')}`,
    component: () => import('@/views/Portfolio.vue'),
    name: 'portfolio',
    meta: {
      isLocked: true,
    },
  },
  {
    path: `/${formatSegment('services')}`,
    component: () => import('@/views/Services.vue'),
    name: 'services',
    meta: {
      isLocked: false,
    },
  },
  {
    path: `/${formatSegment('careers')}`,
    component: () => import('@/views/Careers.vue'),
    name: 'careers',
    meta: {
      isLocked: false,
    },
  },
  {
    path: `/${formatSegment('thankyou')}`,
    component: () => import('@/views/pages/Rabbit.vue'),
    name: 'thank-you',
    // https://github.com/vuejs/router/blob/main/packages/router/CHANGELOG.md
    // props: true,
    meta: {},
  },
  { path: '/contact/', redirect: `/${langDefault}/contact/new-project/` },
  {
    path: '/journal/',
    component: () => import('@/views/Blog.vue'),
    name: 'blog',
    meta: {
      endpoint: 'pages',
    },
  },
  {
    path: '/article/:case/',
    component: () => import('@/views/SingleBlog.vue'),
    name: 'single-blog',
    meta: {
      endpoint: 'article',
    },
  },
  {
    path: '/job/:case/',
    component: () => import('@/views/SingleJob.vue'),
    name: 'single-job',
    meta: {
      endpoint: 'job',
    },
  },
  {
    path: `/${formatSegment('work')}/:case/`,
    component: () => import('@/views/Case.vue'),
    name: 'case',
    props: true,
    meta: {
      endpoint: 'work',
    },
  },
  {
    path: '/registration/',
    component: () => import('@/views/pages/Registration.vue'),
    name: 'registration',
    meta: {
      static: true,
      noChrome: true,
    },
  },
  {
    path: '/:page/:subPage?/',
    component: () => import('@/views/Page.vue'),
    name: 'page',
  },
  {
    path: '/:catchAll(.*)',
    name: 'catch-all',
    component: () => import('@/views/404.vue'),
  },
]

// If multilanguage, add lang segment
if (langAvailable.length > 1) {
  const initialRoutes = [...routes]

  routes = [
    {
      path: `/:lang(${langAvailable.join('|')})`,
      component: Root as Component,
      meta: {
        // ScrollTop all the time for all routes
        scrollTop: false,
      },
      children: initialRoutes
        // Remove catch-all route (there should be only one)
        // and "root" routes (that will stay at the root level)
        .filter(r => r.name !== 'catch-all' && r.meta?.root !== true)
        .map(r => {
          // Remove leading slash
          r.path = r.path.replace(/^\//, '')

          return r
        }),
    },
    {
      path: '/',
      redirect: () => `/${langDefault}/`, // `/${navigator.language.split('-')[0] || langDefault}`
    },
    {
      path: '/:catchAll(.*)',
      name: 'catch-all',
      component: () => import('@/views/404.vue'),
    },
  ]

  // Keep "root" routes
  routes = routes.concat(initialRoutes.filter(r => r.meta?.root === true))
}

export default routes
