import { HeadAttrs } from '@vueuse/head'
import { AxiosResponse } from 'axios'
import { defineStore } from 'pinia'

import { getApiUrl } from '@/config/app'
import { langDefault } from '@/config/languages'
import { fetch } from '@/core/cache'
import { Cta, Data, Language, Picture } from '@/types'
import { logger } from '@/utils/logger'

interface NavLink {
  label: string
  url: string
  target?: string
}

export interface SocialLink {
  type: string
  url: string
}

export interface Address {
  name: string
  street: string
  number: string
  zipcode: string
  city: string
  country: string
  phone: Data
  gmapsUrl: string
}

interface ChromeState {
  address: Address
  cta: Cta
  socials: SocialLink[]
  legals: NavLink[]
  main: NavLink[]
  secondary: NavLink[]
  footer: NavLink[]
  language: Language
  script: HeadAttrs[]
  i18n: Record<string, string>
  noise: Picture | null
  paperFold: Picture | null
}
export type Chrome = ChromeState

const defaultState = (): ChromeState => ({
  address: {} as Address,
  cta: {} as Cta,
  socials: [],
  main: [],
  secondary: [],
  footer: [],
  legals: [],
  language: langDefault,
  script: [],
  i18n: {},
  noise: null,
  paperFold: null,
})

export const useChromeStore = defineStore('chrome', {
  state: defaultState,
  actions: {
    async fetchChrome(language: Language = langDefault) {
      try {
        const path = `${language}/chrome`
        const response = (await fetch(
          path,
          `${getApiUrl()}/chrome`
        )) as AxiosResponse<Chrome>
        const data: Chrome = response?.data

        this.address = data.address
        this.cta = data.cta
        this.socials = data.socials
        this.legals = data.legals
        this.main = data.main
        this.secondary = data.secondary
        this.footer = data.footer
        this.script = data.script
        this.i18n = data.i18n
        // Assets
        this.noise = data.noise
        this.paperFold = data.paperFold

        this.language = language
      } catch (error) {
        logger.error('[fetchChrome]', error)
      }
    },
  },
  getters: {
    t: state => (id: string) => state.i18n[id] || id,
  },
})
