<template>
  <div class="not-found">
    <div class="wrapper">
      <p class="p-medium not-found__headline">
        {{ content.headline }}
      </p>
      <h1 class="h1-custom not-found__title">{{ content.bigTitle }}</h1>
    </div>

    <div ref="canvasContainer" class="not-found__canvas mb-d">
      <canvas ref="canvas"></canvas>
      <div v-if="isTouchDevice && !isActive" class="play">
        <GCtaRounded
          text="Play the game"
          class="not-found__cta"
          name="read-case"
          size="large"
          foreground="#332e2e"
          background="#7BEEFF"
          @click="onClick"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import isMobile from 'ismobilejs'

import { Maze } from '@/components/404/maze'
import { useResize } from '@/utils/aware'
import Ticker from '@/modules/Ticker'

gsap.registerPlugin(ScrollToPlugin)

defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
})

let maze: Maze
// let isPlayable = !isMobile().any
let ticker: Ticker
const canvas = ref<HTMLCanvasElement | null>(null)
const canvasContainer = ref<HTMLCanvasElement | null>(null)
const isActive = ref(true) // Used on mobile

const isTouchDevice = ref(isMobile().apple.device)

const onClick = () => {
  if (isMobile().apple.device) {
    ;(window.DeviceMotionEvent as any).requestPermission()
    isActive.value = true
    maze.isActive = true
  }

  gsap.to(window, {
    scrollTo: {
      y: canvas.value || 0,
      offsetY: 100,
      autoKill: false,
    },
  })
}

const draw = (time: number, delta: number) => {
  if (maze) {
    maze.draw(time, delta)
  }
}

useResize(() => {
  if (maze) {
    maze.onResize(
      canvasContainer.value?.offsetWidth || 0,
      canvasContainer.value?.offsetHeight || 0
    )
  }
})

onMounted(() => {
  if (canvas.value) {
    ticker = new Ticker(draw)
    maze = new Maze(canvas.value)

    if (isMobile().apple.device) {
      isActive.value = false
      maze.isActive = false
    }

    ticker.fps(60)
  }
})

onBeforeUnmount(() => {
  if (maze) {
    ticker.destroy()
    maze.destroy()
  }
})
</script>

<style lang="scss" scoped>
.not-found {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-found__headline {
  // prettier-ignore
  @include fluid(margin-top,(xxs: 80px, xxl: 120px));

  margin-bottom: 3.5rem;
  text-align: center;
}

.not-found__title {
  text-align: center;
}

.play {
  @include get-all-space;

  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-found__canvas {
  position: relative;
  display: block;
  width: min(90vh, 100vw);
  height: min(90vh, 100vw);
  margin: -5% auto 0;

  canvas {
    width: 100%;
    height: 100%;
  }
}

// .not-found__cta {
//   @include center-xy;

//   &.is-hidden {
//     display: none;
//   }
// }
</style>
