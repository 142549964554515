import { defineStore } from 'pinia'

import { CaseHighlight } from '@/types/views/cases'
import { TierResult } from 'detect-gpu'

interface UiState {
  noScroll: boolean
  hasTransition: 'page' | 'menu' | 'none'
  isSmallLarger: boolean
  isMediumLarger: boolean
  headerHeight: number
  cases: {
    current: number
    items: CaseHighlight[]
    isFreeScroll: boolean
    isFullViewport: boolean
  }
  castleConfig: {
    balloonID: number
    baseID: number
    bodyID: number
  }
  gpuStats: TierResult | null
}

const defaultState = (): UiState => ({
  gpuStats: null,
  noScroll: false,
  hasTransition: 'none',
  isSmallLarger: true,
  isMediumLarger: true,
  headerHeight: 0,
  cases: {
    current: 0,
    items: [],
    isFreeScroll: false,
    isFullViewport: true,
  },
  castleConfig: {
    balloonID: 0,
    baseID: 0,
    bodyID: 0,
  },
})

export const useUiStore = defineStore('ui', {
  state: defaultState,
  getters: {
    currentCase: state => state.cases.items[state.cases.current],
    deviceTier: (state): number => {
      if (
        !state.gpuStats ||
        (!state.gpuStats?.device &&
          !state.gpuStats?.fps &&
          !state.gpuStats?.gpu?.includes('apple gpu'))
      ) {
        return 1
      }

      if (state.gpuStats?.gpu?.includes('apple gpu')) {
        return 3
      }

      return state.gpuStats.tier
    },
  },
  actions: {
    toggleScroll(value?: boolean) {
      this.noScroll = value ?? !this.noScroll

      if (this.noScroll === false) {
        document.documentElement.classList.add('no-scroll')
        document.documentElement.dataset.scroll = 'no'
      } else {
        document.documentElement.classList.remove('no-scroll')
        document.documentElement.dataset.scroll = 'yes'
      }
    },
  },
})

export const hasScroll = () => window.innerWidth > document.body.clientWidth
