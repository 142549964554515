import isMobile from 'ismobilejs'

/* eslint-disable @typescript-eslint/no-explicit-any */
export class Sensor {
  public x = 0
  public y = 0
  public z = 0
  public alpha = 0
  public beta = 0
  public gamma = 0
  public tilt = 0
  public yaw = 0
  public roll = 0
  public rotationRate = {
    alpha: 0,
    beta: 0,
    gamma: 0,
  }
  private toRadians = Math.PI / 180

  constructor() {
    // #DEV
    // this.toRadians = window.Modernizr.ios ? Math.PI / 180 : 1;

    this.updateOrientation = this.updateOrientation.bind(this)
    this.updateMotion = this.updateMotion.bind(this)
    this.bind()
  }

  bind() {
    window.addEventListener('devicemotion', this.updateMotion)
    window.addEventListener('deviceorientation', this.updateOrientation)
  }

  destroy() {
    window.removeEventListener('devicemotion', this.updateMotion)
    window.removeEventListener('deviceorientation', this.updateOrientation)
  }

  updateMotion(e: Record<string, any>) {
    switch (window.orientation) {
      case 0:
        this.x = -e.accelerationIncludingGravity.x
        this.y = e.accelerationIncludingGravity.y
        this.z = e.accelerationIncludingGravity.z
        if (e.rotationRate) {
          this.rotationRate.alpha = e.rotationRate.beta * this.toRadians
          this.rotationRate.beta = -e.rotationRate.alpha * this.toRadians
          this.rotationRate.gamma = e.rotationRate.gamma * this.toRadians
        }
        break
      case 180:
        this.x = e.accelerationIncludingGravity.x
        this.y = -e.accelerationIncludingGravity.y
        this.z = e.accelerationIncludingGravity.z
        if (e.rotationRate) {
          this.rotationRate.alpha = -e.rotationRate.beta * this.toRadians
          this.rotationRate.beta = e.rotationRate.alpha * this.toRadians
          this.rotationRate.gamma = e.rotationRate.gamma * this.toRadians
        }
        break
      case 90:
        this.x = e.accelerationIncludingGravity.y
        this.y = e.accelerationIncludingGravity.x
        this.z = e.accelerationIncludingGravity.z
        if (e.rotationRate) {
          this.rotationRate.alpha = e.rotationRate.alpha * this.toRadians
          this.rotationRate.beta = e.rotationRate.beta * this.toRadians
          this.rotationRate.gamma = e.rotationRate.gamma * this.toRadians
        }
        break
      case -90:
        this.x = -e.accelerationIncludingGravity.y
        this.y = -e.accelerationIncludingGravity.x
        this.z = e.accelerationIncludingGravity.z
        if (e.rotationRate) {
          this.rotationRate.alpha = -e.rotationRate.alpha * this.toRadians
          this.rotationRate.beta = -e.rotationRate.beta * this.toRadians
          this.rotationRate.gamma = e.rotationRate.gamma * this.toRadians
        }
        break
      default:
        break
    }

    if (isMobile().android.device) {
      this.x *= -1
      this.y *= -1
      this.z *= -1
    }
  }

  updateOrientation(e: Record<string, any>) {
    switch (window.orientation) {
      case 0:
        this.alpha = -e.beta * this.toRadians
        this.beta = -e.alpha * this.toRadians
        this.gamma = e.gamma * this.toRadians
        break
      case 180:
        this.alpha = e.beta * this.toRadians
        this.beta = e.alpha * this.toRadians
        this.gamma = -e.gamma * this.toRadians
        break
      case 90:
        this.alpha = e.gamma * this.toRadians
        this.beta = -e.alpha * this.toRadians
        this.gamma = e.beta * this.toRadians
        break
      case -90:
        this.alpha = -e.gamma * this.toRadians
        this.beta = e.alpha * this.toRadians
        this.gamma = -e.beta * this.toRadians
        break
      default:
        break
    }
    this.tilt = e.beta * this.toRadians
    this.yaw = e.alpha * this.toRadians
    this.roll = -e.gamma * this.toRadians
  }
}
