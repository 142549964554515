import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'

import { makeQueryablePromise, QueryablePromise } from '@/utils/async'
import { Chrome } from '@/stores/chrome'
import { ResourceSingle } from '@/stores/resource'

const isProd = ['production', 'pre'].includes(import.meta.env.RELEASE as string)

type ApiResponse = Chrome | ResourceSingle
interface CacheData {
  response: QueryablePromise<AxiosResponse<ApiResponse>>
  created: number
}

/**
 * Cache HTTP responses
 * Only client-side + production
 * Avoid object caching on SSR, use Express + node-cache
 */
const cacheDuration = 1000 * 60 * 10 // 10 minutes
const expires = -1

export const data: Map<string, CacheData> = new Map()
export const fetch = (
  key: string,
  url: string,
  config?: AxiosRequestConfig
) => {
  if (expires < 0) {
    return makeQueryablePromise(axios.get(url, config))
  }

  if (!data.has(key) || data.get(key)!.created < Date.now() - expires) {
    data.set(key, {
      response: makeQueryablePromise(axios.get(url, config)),
      created: Date.now(),
    })
  }

  return data.get(key)!.response
}
