/* eslint-disable @typescript-eslint/no-explicit-any */
export const wait = (s = 1): Promise<any> =>
  new Promise(resolve => setTimeout(resolve, s * 1000))

export interface Deferred<T> extends Promise<T> {
  resolve?: (value: T) => void
  reject?: (reason?: any) => void
}

export const deferred = <T>() => {
  type DeferredResolve = (value: T | PromiseLike<T>) => void | undefined
  type DeferredReject = (reason?: any) => void | undefined

  let _resolve: DeferredResolve = undefined as unknown as DeferredResolve
  let _reject: DeferredReject = undefined as unknown as DeferredReject

  const promise = new Promise<T>((resolve, reject) => {
    _resolve = resolve
    _reject = reject
  }) as Deferred<T>

  promise.resolve = _resolve
  promise.reject = _reject

  return promise
}

/**
 * Queryable promise
 * Allow to access internal status
 * https://medium.com/@UtkarshPramodGupta/how-to-make-a-stateful-promise-in-javascript-4e08418716ad
 */
// Promise original
// type Resolver<T> = (value?: T | PromiseLike<T> | undefined) => void
type Resolver<T> = (value: T) => void
type Rejecter = (reason?: any) => void
type Executor<T> = (resolve: Resolver<T>, reject: Rejecter) => void
export type PromiseStatus = 'pending' | 'fulfilled' | 'rejected'

export class QueryablePromise<T> extends Promise<T> {
  private _status: PromiseStatus

  constructor(executor: Executor<T>) {
    super((resolve: Resolver<T>, reject: Rejecter) =>
      executor(
        (data: T) => {
          resolve(data)
          this._status = 'fulfilled'
        },
        (err: Error) => {
          reject(err)
          this._status = 'rejected'
        }
      )
    )
    this._status = 'pending'
  }

  get status() {
    return this._status
  }
}

export function makeQueryablePromise<T>(
  promise: Promise<T>
): QueryablePromise<T> {
  // Don't modify any promise that has been already modified.
  if ((promise as QueryablePromise<T>).status) {
    return promise as QueryablePromise<T>
  }

  // Set initial status.
  let _status: PromiseStatus = 'pending'

  // Observe the promise, saving the fulfillment (or not) in a closure scope.
  const result: QueryablePromise<T> = promise.then(
    v => {
      _status = 'fulfilled'

      return v
    },
    e => {
      _status = 'rejected'

      throw e
    }
  ) as QueryablePromise<T>

  // Define status getter.
  Object.defineProperty(result, 'status', {
    get: () => _status,
  })

  return result
}
