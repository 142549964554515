<template>
  <label :class="{ disabled }" class="switch">
    <input
      :checked="Boolean(modelValue)"
      :disabled="disabled"
      class="input"
      type="checkbox"
      @change="$emit('update:modelValue', Boolean($event.target?.checked))"
    />
    <div class="slider">
      <span class="slider__circle"></span>
    </div>
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: [Boolean, Number],
      default: false,
    },
    disabled: Boolean,
  },
  emits: ['update:modelValue'],

  setup() {
    return {}
  },
})
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: block;
  width: 50px;
  height: 30px;

  .input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &.disabled {
    pointer-events: none;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--c-background);
  border: 1px solid var(--c-foreground);
  border-radius: 40px;
  transition: 0.2s;
}

.slider__circle {
  position: absolute;
  bottom: 3px;
  left: 4px;
  width: 22px;
  height: 22px;
  background-color: var(--c-foreground);
  border-radius: 50%;
  transition: 0.2s;

  .disabled & {
    opacity: 0.2;
  }
}

// .input:focus + .slider {
//   box-shadow: 0 0 1px gray;
// }

.input:checked + .slider .slider__circle {
  // background-color: 1px solid var(--c-foreground);
  transform: translateX(19px);
}
</style>
