import colorParse from 'color-parse'
import gsap from 'gsap'

import { Colors, ColorKey } from '@/types'

interface ColorsData {
  color: string
  duration: number
  el: HTMLElement
}

const defaultDuration = 0.8
let styles: CSSStyleDeclaration | null = null
const currents: Colors = {
  foreground: '',
  background: '',
}

export const getValue = (key: ColorKey, el: HTMLElement) => {
  if (!styles) {
    styles = getComputedStyle(el)
  }

  return styles.getPropertyValue(`--c-${key}`)
}

export const getColors = (el: HTMLElement = document.documentElement) => {
  const foreground = getValue('foreground', el)
  const background = getValue('background', el)

  return {
    foreground,
    background,
  }
}

const tweenColor = async (
  key: ColorKey,
  { color, duration, el }: ColorsData
) => {
  const proxy = {
    color: getValue(key, el),
  }

  await gsap.to(proxy, {
    color,
    duration,
    onUpdate() {
      el.style.setProperty(`--c-${key}`, proxy.color)
      el.style.setProperty(`--c-${key}-rgb`, getRgbCss(proxy.color))
    },
  })
}

export const setBackground = async (
  color: string,
  duration = defaultDuration,
  el: HTMLElement = document.documentElement
) => {
  await tweenColor('background', { color, duration, el })
}

export const setForeground = async (
  color: string,
  duration = defaultDuration,
  el: HTMLElement = document.documentElement
) => {
  await tweenColor('foreground', { color, duration, el })
}

export const setColors = async (colors: Colors, duration = defaultDuration) => {
  const setters = []

  if (colors.background && colors.background !== currents.background) {
    currents.background = colors.background
    setters.push(setBackground(colors.background, duration))
  }

  if (colors.foreground && colors.foreground !== currents.foreground) {
    currents.foreground = colors.foreground
    setters.push(setForeground(colors.foreground, duration))
  }

  await Promise.all(setters)
}

export const swapColors = (colors: Colors) => {
  Object.keys(colors).forEach(key => {
    const color = colors[key as ColorKey]

    document.documentElement.style.setProperty(`--c-${key}`, color)
    document.documentElement.style.setProperty(
      `--c-${key}-rgb`,
      getRgbCss(color)
    )
  })
}

// TODO: reset local custom property
// Convention --fg, --bg ?
// export const resetLocalColors = () => {}

// From rgba() to rgb()
// To avoid conflict between gsap tweened color (rgba(), whatever)
// and THREE Color (no transparency)
export const toRgb = (color: string) =>
  color.replace(/^rgba\((\d+,\d+,\d+),.+\)$/, 'rgb($1)')
export const getRgb = (color: string) => colorParse(color).values
export const getRgbCss = (color: string) => getRgb(color).join(', ')
export const getRgbaString = (color: string, opacity = 0) => {
  const [r, g, b] = getRgb(color)

  return `rgba(${r},${g},${b},${opacity})`
}
