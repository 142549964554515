import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import {
  Scene,
  WebGLRenderer,
  PerspectiveCamera,
  sRGBEncoding,
  PCFSoftShadowMap,
} from 'three'
export default class Base {
  public canvas!: HTMLCanvasElement
  public scene = new Scene()
  public gltfLoader = new GLTFLoader()
  private sizes!: {
    width: number
    height: number
  }
  public delta!: number
  public elapsedTime!: number
  public renderer!: WebGLRenderer
  public camera!: PerspectiveCamera

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath(
      'https://www.gstatic.com/draco/versioned/decoders/1.3.6/'
    )
    this.gltfLoader.setDRACOLoader(dracoLoader)

    this.sizes = {
      width: canvas.offsetWidth,
      height: canvas.offsetHeight,
    }
    this.renderer = new WebGLRenderer({
      alpha: true,
      canvas: this.canvas,
      antialias: true,
    })
    this.renderer.shadowMap.enabled = true
    this.renderer.shadowMap.type = PCFSoftShadowMap
    this.renderer.setClearColor(0x000000, 0)
    this.renderer.outputEncoding = sRGBEncoding
    this.renderer.setSize(this.sizes.width, this.sizes.height)
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    this.setCamera()
  }

  setCamera() {
    this.camera = new PerspectiveCamera(
      75,
      this.sizes.width / this.sizes.height,
      0.1,
      100
    )
    this.camera.position.x = 1
    this.camera.position.y = 1
    this.camera.position.z = 2
    this.scene.add(this.camera)
  }

  onResize(x: number, y: number) {
    // Update sizes
    this.sizes.width = x || this.canvas.offsetWidth
    this.sizes.height = y || this.canvas.offsetHeight

    // Update camera
    this.camera.aspect = this.sizes.width / this.sizes.height
    this.camera.updateProjectionMatrix()

    // Update renderer
    this.renderer.setSize(this.sizes.width, this.sizes.height)
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1))
  }

  // eslint-disable-next-line class-methods-use-this, no-empty-function, @typescript-eslint/no-empty-function
  public tick() {}

  public draw(time: number, delta: number) {
    this.delta = delta / 100
    this.elapsedTime = time

    this.tick()

    this.renderer.render(this.scene, this.camera)
  }
}
